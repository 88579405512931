<template>
  <div class="s-order-radio">
    <div class="s-radio-box"
         v-for="(item,index) in options"
         :key="index" @click="changeTab(item)"
         :class="{active:val===item.attrKey}"
    >
      {{ item.attrVal }}
    </div>
  </div>
</template>
<script>
export default {
  name: "orderRadio",
  props: {
    data: {
      type: [Number, String],
      default: 1
    },
    options: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      val: this.data || ''
    }
  },
  watch: {
    data: {
      handler (val) {
        this.val = val
      },
      immediate: true
    }
  },
  methods: {
    changeTab (item) {
      this.$emit('update:data', item.attrKey)
    }
  }

}
</script>
<style scoped lang="stylus">
.s-order-radio {
  display flex
  align-items center
  flex-wrap wrap;
  .s-radio-box {
    width: 140px;
    height: 36px;
    line-height: 36px;
    border-radius: 4px;
    background-color: #F4F6F8;
    color: #1D2129;
    font-size: 14px;
    text-align: center;
    margin-right 10px;
    margin-bottom 10px;
    box-sizing border-box
    &.active {
      background-color: rgba(22, 93, 255, 0.06);
      border: 2px solid #165DFF;
      color: #165DFF;
      line-height: 34px;
    }
  }
}
</style>
