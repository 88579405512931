import { render, staticRenderFns } from "./try-info.vue?vue&type=template&id=018f5020&scoped=true"
import script from "./try-info.vue?vue&type=script&lang=js"
export * from "./try-info.vue?vue&type=script&lang=js"
import style0 from "./try-info.vue?vue&type=style&index=0&id=018f5020&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "018f5020",
  null
  
)

export default component.exports