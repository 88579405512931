<template>
  <div class="pay">
    <div class="pay-top">
      <div class="haibaoPic">
        <!--        <img :src="item.showImageUrl" alt="" />-->
        <img class="backPic" :src="item.showImageUrl" alt="" />
        <img class="version-logo"
             :src="getCornerPic(item.attrs)"
             alt="">
        <!-- <img src="../../assets/老板看报告插图 (1).png" alt="" /> -->
      </div>
      <div style="padding-left: 0.3rem;">
        <div class="pay-title">{{ item.productName ? item.productName : "老板看报告" }}</div>
        <div class="pay-price">
          <div class="productUnitPrice">
            ￥<span class="price-title" style="margin-right: 10px;">{{formatToDecimal(item.currentPrice) }}</span>
            <span class="price-title" v-if="item.discount">￥{{formatToDecimal(item.salesUnitPrice) }}</span>
          </div>
          <div class="pay-del">
            <div class="item item-left" @click="delNum" :id="num == 1 || num == '1' ? 'zero' : ''"
                 :class="item.bundlingNum?'disabled':''">
              -
            </div>
            <!-- <div class="item item-center">{{ num }}</div> -->
            <div class="item item-center">{{ item.bundlingNum? item.bundlingNum :num }}</div>
            <div class="item item-right" @click="addNum" :class="item.bundlingNum?'disabled':''">+
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pay-bottom-text" v-if="item.bundlingNum!=null">
      <i class="el-icon-warning-outline"></i>
      <div>
        免费产品限购{{ item.bundlingNum }}份
      </div>
    </div>
    <!--商品价格-->
    <div class="pay-total">
      <div class="txt">应付金额</div>
      <div class="txtMoney">
        <span>￥</span> {{ formatToDecimal(Number(goodsCount) * Number(item.currentPrice)) }}
      </div>
    </div>
  </div>
</template>
<script>
import { formatToDecimal } from "../../utils/format";
import FancyDecimal from "@/components/FancyDecimal.vue";
import { getAttrVal, getCornerPic } from '@/utils/help.js'

export default {
  components: { FancyDecimal },
  props: {
    type: [Number, String],
    goodsCount: [Number, String],
    item: [Object, Array],
  },
  data () {
    return { num: 1 };
  },
  methods: {
    formatToDecimal,
    getAttrVal, getCornerPic,
    delNum () {
      // if (this.num == 1) return;
      if (this.num == 1 || this.item.bundlingNum) return;
      this.num--;
      this.$emit("changeNum", this.num);
    },
    addNum () {
      if (this.item.bundlingNum) {
        return;
      }

      this.num++;
      this.$emit("changeNum", this.num);
    },
    handleChange (value) {
      console.log(value);
    },
    getBaseInfos () {
      if (this.isWeiXin()) {
        const code = this.getUrlParam("code"); // 截取路径中的code
        if (code == null || code === "") {
          let url = "https://channel.ai-tax.cn";
          window.location.href =
            "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx4de932054829ef8c&redirect_uri=" +
            encodeURIComponent(url) +
            "&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect";
        } else {
        }

        if (code != "" && code != null) {
          this.wxCode = code;
          // alert(code);
          // this.getOpenid(code);
        }
      } else {
      }
    },
    //判断是不是微信浏览器。
    isWeiXin () {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    // 编码函数
    getUrlParam (name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
      var r = window.location.search.substr(1).match(reg); //匹配目标参数
      if (r != null) return unescape(r[2]);
      return null; //返回参数值
    },
    toDetail (orderNo) {
      this.$router.push({
        path: "/detail",
        query: {
          orderNo: orderNo,
        },
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.pay {
  width: 7.1rem;
  background: #FFFFFF;
  border-radius: 0.2rem 0.2rem 0.2rem 0.2rem;
  opacity: 1;
  font-size: 0.24rem;
  padding: 0.2rem;
  box-sizing: border-box;
  .pay-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.28rem;
    padding-top: 0.3rem;
    padding-bottom: 0.1rem;
    border-top: 1px solid #E6E8EA;
    margin-top: 0.2rem;
    .txt {
      line-height: 0.4rem;
    }
    .txtMoney {
      color: #165DFF;
      vertical-align: baseline;
      display: flex;
      font-family: DIN Alternate-Bold, DIN Alternate;
      font-size: 0.36rem;
      span {
        font-size: 0.24rem;
        position: relative;
        top: 5px;
      }
    }
    .floatPrice {
      margin-top: 0.12rem;
    }
  }
  .pay-top {
    display: flex;
    .pay-title {
      font-size: 0.28rem;
      font-weight: bold;
      color: #0E1429;
      line-height: 0.4rem;
      margin-top: 0.14rem;
      margin-bottom: 0.46rem;
    }
    .pay-price {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 0.36rem;
      line-height: 0.42rem;
      color: #3F74F6;
      .pay-del {
        display: flex;
        align-items: center;
        margin-left: 0.91rem;
        .item {
          width: 0.52rem;
          height: 0.56rem;
          line-height: 0.56rem;
          background: #FFFFFF;
          opacity: 1;
          border: 1px solid rgba(14, 20, 41, 0.16);
          text-align: center;
          color: rgba(51, 51, 51, 1);
          font-size: 0.28rem;
        }
        .item-center {
          border-left: none;
          border-right: none;
          width: 0.76rem;
        }
        .item-left {
          border-radius: 0.08rem 0 0 0.08rem;
        }
        .disabled {
          color: #DFDFE0;
        }
        .item-right {
          border-radius: 0 0.08rem 0.08rem 0;
        }
      }
      .productUnitPrice {
        width: 2rem;
        font-size: 0.24rem;
        .price-title {
          font-family: DIN Alternate-Bold, DIN Alternate;
          font-size: 0.36rem;
        }
        .price-title + .price-title {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.24rem;
          color: #A4A6A9;
          text-decoration-line: line-through;
        }
      }
    }
    .pay-status {
      display: flex;
      align-items: center;
      align-self: start;
      font-size: 0.26rem;
      line-height: 0.37rem;
      margin-top: 0.16rem;
      margin-left: auto;
      color: #18CC87;
      .pay-status-icon {
        width: 0.14rem;
        height: 0.14rem;
        background: #18CC87;
        opacity: 1;
        border-radius: 50%;
        margin-right: 0.12rem;
      }
    }
  }
  .pay-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.2rem;
    .pay-time {
      font-size: 0.26rem;
      color: #0E1429;
      line-height: 0.37rem;
    }
    .pay-btn {
      width: 1.44rem;
      height: 0.56rem;
      line-height: 0.56rem;
      background: #FFFFFF;
      border-radius: 0.1rem;
      opacity: 1;
      border: 1px solid #2970F4;
      text-align: center;
      color: #2970F4;
      font-size: 0.26rem;
    }
  }
  .pay-bottom-text {
    display: flex;
    align-items: baseline;
    width: 6.5rem;
    //background: rgba(253,179,32,0.08);
    //border-radius: 0.1rem;
    opacity: 1;
    //margin-top: 0.3rem;
    color: #FDB320;
    font-size: 0.24rem;
    padding: 0.2rem 0.4rem 0.1rem 0rem;
    box-sizing: border-box;
    //line-height: 1.7;
    .el-icon-warning-outline {
      margin-right: 0.1rem;
      font-size: 0.32rem;
    }
  }
}
.pay-icon {
  width: 1.61rem;
  height: 1.61rem;
  padding-right: 0.3rem;
  img {
    width: 100%;
    height: 100%;
    border-radius: 0.16rem;
  }
}
#zero {
  color: #DFDFE0;
}
</style>
