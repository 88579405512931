import axios, {
  get,
  post
} from "@/axios";
// import store from "@/store";

const base = process.env.VUE_APP_API_ROOT;
const baseContent = `${base}/content`;
const basePlat = `${base}/plat`;

//
export const updateDataExpireTime = (programId, deleteDataType) => {
  return get(`${basePlat}/longPeriodFiscal/updateDataExpireTime?programId=${programId}&deleteDataType=${deleteDataType}`)
    .then(res => res.data);
};

// 判断当前检测企业数据完整性
export const getIntegrity = (params) => {
  return get(`${basePlat}/model/getIntegrity`, params)
    .then(res => res.data);
};

// 完善企业地区行业数据
export const supplementIndustryAndArea = (params) => {
  return axios
    .post(`${basePlat}/model/supplementIndustryAndArea`, params)
    .then(res => res.data);
};

export const getRegion = () => {
  return get(`${basePlat}/dmDq/loadAreaTree`)
    .then(res => res.data);
};

// 查看公司名称
export const businessDataFilter = (word) => {
  return axios
    .get(`${basePlat}/businessData/search?word=${word}&pageNum=1&pageSize=10`)
    .then(res => res.data);
};
//

export const getInvoiceSection = (id) => {
  return axios
    .get(`${basePlat}/pdfData/getInvoiceSection?programId=${id}`)
    .then(res => res.data);
};

// 查看税号取数状态
export const getAuthStatus = (taxNo, programId) => {
  return axios
    .get(`${base}/daqc/thirdAuth/getAuthStatus?taxNo=${taxNo}&programId=${programId}`)
    .then(res => res.data);
};

// 陕西宽古获取验证码
export const newShanxiGetCode = (params, prefix) => {
  if (prefix === 'daqc') {
    return axios
      .post(`${base}/${prefix}/autoFetchData/api/sendValidateCode`, params)
      .then(res => res.data);
  } else {
    return axios
      .post(`${base}/${prefix}/breeze/sendValidateCode`, params)
      .then(res => res.data);
  }
};

// 陕西宽古授权
export const newShanxiBreeze = (params, prefix) => {
  if (prefix === 'daqc') {
    return axios
      .post(`${base}/${prefix}/autoFetchData/api/authorizationAndCreateTaskByYzm`, params)
      .then(res => res.data);
  } else {
    return axios
      .post(`${base}/${prefix}/breeze/authorizationAndCreateTaskByYzm`, params)
      .then(res => res.data);
  }
};

/**
 * 新版授权 浙江登录
 * @param params
 * @param {"plat"|"daqc"} prefix
 * @returns {Promise<unknown>}
 */
export const zjBreezeLogin = (params, prefix) => {
  if (prefix === 'daqc') {
    return axios
      .post(`${base}/${prefix}/autoFetchData/api/login`, params)
      .then(res => res.data);
  } else {
    return axios
      .post(`${base}/${prefix}/breeze/login`, params)
      .then(res => res.data);
  }

};
// 浙江授权
export const zjBreezeLoginCreateTask = (params, prefix) => {
  if (prefix === 'daqc') {
    return axios
      .post(`${base}/${prefix}/autoFetchData/api/authorizationAndCreateTask`, params)
      .then(res => res.data);
  } else {
    return axios
      .post(`${basePlat}/breeze/authorizationAndCreateTask `, params)
      .then(res => res.data);
  }

};

export const zjBreezeLoginV2 = (params, prefix) => {
// 第三方接口还没改
  if (prefix === 'daqc') {
    return axios
      .post(`${base}/${prefix}/autoFetchData/api/login/V2`, params)
      .then(res => res.data);
  } else {
    return axios
      .post(`${basePlat}/breeze/login/V2`, params)
      .then(res => res.data);
  }
};

// 北京授权
export const zjBreezeLoginCreateTaskBj = (params, prefix) => {
  if (prefix === 'daqc') {
    return axios
      .post(`${base}/${prefix}/autoFetchData/api/authorizationAndCreateTask/beijing`, params)
      .then(res => res.data);
  } else {
    return axios
      .post(`${basePlat}/breeze/authorizationAndCreateTask/beijing `, params)
      .then(res => res.data);
  }

};


/**
 * 重庆取数 获取取数方式
 * @param params
 * @param {"plat"|"daqc"} prefix
 * @returns {Promise<unknown>}
 */
export const getLoginMethod = (params, prefix) => {
  return axios
    .post(`${base}/${prefix}/breeze/getLoginMethod`, params)
    .then(res => res.data);
};

// 第一次获取验证码
export const sendAuthSmsOne = (params, prefix) => {
  return axios
    .post(`${base}/${prefix}/breeze/sendAuthSms`, params)
    .then(res => res.data);
};

export const h5GetResultBasicInfo = (id) => {
  return axios
    .get(`${basePlat}/model/getResultBasicInfo?programId=${id}`)
    .then(res => res.data);
};

//审计小工具 检测记录
export const auditList = params => {
  return axios
    .post(`${basePlat}/researchReport/getPage `, params)
    .then(res => res.data);
};

export const auditRuleListAdd = params => {
  return axios
    .post(`${basePlat}/researchReport/add`, params)
    .then(res => res.data);
};

export const resultSuccess = params => {
  return axios
    .post(`${basePlat}/researchReport/resultSuccess`, params)
    .then(res => res.data);
};

// 删除报告
export const researchLoginRecordDel = (id) => {
  return axios
    .get(`${basePlat}/researchReport/delResearchReport?id=${id}`)
    .then(res => res.data);
};

// 进去报告密码
export const auditCheckPassword = (password, token) => {
  return axios
    .get(`${basePlat}/researchPassword/checkPassword?password=${password}&token=${token}`)
    .then(res => res.data);
};

// 查看是否输入密码
export const researchLoginRecord = (token) => {
  return axios
    .get(`${basePlat}/researchLoginRecord/formalApproach?token=${token}`)
    .then(res => res.data);
};

//首页接口
export const homemsg = params => {
  return get(`${baseContent}/index/findAllImg`, params);
};

// 山东授权
export const breezeOuterSDCheck = params => {
  return post(`${base}/open/commomInspect/inspectForFetchData`, params).then(res => res
    .data);
};

// 山东授权
export const breezeOuterSD = params => {
  return post(`${base}/daqc/shandong/api/thirdFiling`, params).then(res => res.data);
};

//首页广告展示接口
export const adInHomePage = async () => {
  const url = `${baseContent}/ad/show`;
  const res = await get(url);
  return res.data;
};
//登录接口
export const requestLogin = params => {
  return post(`${basePlat}/bCompanyUser/login`, params).then(res => res.data);
};
//短信登录接口
export const newrequestLogin = params => {
  return post(`${basePlat}/bCompanyUser/login/code`, params).then(res => res.data);
};
//获取注册验证码
export const getRegisterCode = params => {
  return get(`${basePlat}/bCompanyUser/code/REGISTER/` + params).then(res => res.data);
};
//获取注册验证码new
export const newgetRegisterCode = params => {
  return get(`${basePlat}/bCompanyUser/code/LOGINNOTICE/` + params).then(res => res.data);
};
//注册接口
export const register = params => {
  return post(`${basePlat}/bCompanyUser/register?code=` + params.code, params)
    .then(res => res.data);
};
//全国纳税人异常信息查询
export const getycmessage = params => {
  return get(`${basePlat}/xxk/ycnsr/hz/${params.token}?nsrxx=${params.nsrxx}`)
    .then(res => res.data);
};
// 修改密码
export const changepw = params => {
  return post(`${basePlat}/bCompanyUser/info/pwd?token=` + params.token, params)
    .then(res => res.data);
};
// 设置密码
export const changepwnew = params => {
  return post(`${basePlat}/bCompanyUser/changePwd`, params).then(res => res.data);
};

// 绑定新的手机
export const newphone = params => {
  return get(`${basePlat}/bCompanyUser/changemobile`, params).then(res => res.data);
};
//获取企业基本信息
export const getbasicinfo = params => {
  return get(`${basePlat}/bCompany/load/` + params).then(res => res.data);
};

/**
 * 保存企业信息
 * @param {{companyId:number
 * dq:string
 * hyDm:string,
 * scale:number,
 * type:{0}
 * userToken:string
 * }} params
 * @returns {Promise<unknown>}
 */
export const saveCompanyInfo = params => {
  return post(`${basePlat}/bCompany/saveCompanyInfo`, params).then(res => res.data);
};
//获取企业的companyId(更改原来储存在缓存的companyId为接口获取，为了方便把getCompanyId接口放置在ceping.js的api接口JS中)
export const getCompanyId = async token => {
  const url = `${basePlat}/bCompany/apply/query?token=${token}`;
  const res = await get(url);
  return res.data;
};

//获取行业的接口
export const getHy = params => {
  return get(`${basePlat}/dmHy/load`, params).then(res => res.data);
};
//查找具体行业信息
export const findhydm = params => {
  return get(`${basePlat}/dmHy/loadByHyDm`, params).then(res => res.data);
};
// 获取2级行业信息
export const dmHyMore = params => {
  return get(`${basePlat}/dmHy/load/${params.hy}?hyDm=${params.hycode}`).then(res => res.data);
};
//切换企业下拉列表
export const changecompanyselect = params => {
  return get(`${basePlat}/bCompany/switchCompanyPage`, params)
    .then(res => res.data);
};
//切换企业
// export const changecompany = async params => {
//   await store.cache.delete("loadCompanyInfo"); //清空缓存中的企业信息

//   return get(
//       `${basePlat}/bCompany/switchCompanyButton`,
//       params
//     )
//     .then(res => res.data);
// };
//切换企业制度
// export const changekjzd = async params => {
//   await store.cache.delete("loadCompanyInfo"); //清空缓存中的企业信息

//   return post(`${basePlat}/bCompany/changeKjzd`, params).then(res => res.data);
// };
//更新行业
// export const changehyaix = async params => {
//   await store.cache.delete("loadCompanyInfo"); //清空缓存中的企业信息
//   return post(`${basePlat}/dmHy/save/${params.token}?hyDm=${params.hyDm}`).then(res => res
//     .data);
// };
//测试用户新增企业//本地调试
export const testaddcompany = params => {
  return post(`${basePlat}/bCompany/applytest/v1?token=` + params.token, params, false)
    .then(res => res.data);
};
//企业认证申请
export const renzhengApply = async params => {
  return post(`${basePlat}/bCompany/apply/v1?token=` + params.token, params)
    .then(res => res.data);
};
//企业信息审核
export const getCompanyrz = params => {
  return post(`${basePlat}/aip/getCompanyInfo?keyWord=${params.keyWord}`, params)
    .then(res => res.data);
};

//个税接口
export const getTaxInfo = params => {
  return post(`${basePlat}/personTax/getTaxInfo`, params).then(res => res.data);
};
//五险一金
export const getShebaoInfo = params => {
  return post(`${basePlat}/personTax/getShebaoInfo`, params).then(res => res.data);
};

/****
 * 账户信息api
 *
 */

//查询企业信息
export const fetchCompanyInfo = async params => {
  const url = `${basePlat}/bCompany/load/${params.companyID}`;
  const res = await get(url);
  return res.data;
};
// 消息中心
export const messageList = params => {
  return post(`${baseContent}/category/loadByPage`, params).then(res => res.data);
};
//忘记密码接口
export const forgetpw = params => {
  return post(`${basePlat}/bCompanyUser/info/pwd/reset`, params).then(res => res.data);
};
//忘记密码验证码
export const getRestpwdCode = params => {
  return get(`${basePlat}/bCompanyUser/code/RESTPWD/` + params).then(res => res.data);
};
// 获取验证手机号验证码
export const getRestmobileCode = params => {
  return get(`${basePlat}/bCompanyUser/code/RESETMOBILE/` + params.mobile).then(res => res.data);
};
//确认验证码
export const resetmobile = params => {
  return get(`${basePlat}/bCompanyUser/resetmobile?token=` + params.token + "&code=" + params
      .code)
    .then(res => res.data);
};
/****
 * 检测报表
 */
//显示需要上传的报表
export const showModules = params => {
  return post(`${basePlat}/model/showModules?bankId=` + params.bankId + "&qykjzd=" + params
      .qykjzd,
      params)
    .then(res => res.data);
};
//马上咨询  http://120.55.107.135:9014/user_m/addUser_m
export const nowzixun = params => {
  return post(`${baseContent}/user_m/addUser_m`, params).then(res => res.data);
};
// 用户提交专家解答短信通知
export const sendMsg = params => {
  return post(`${basePlat}/bCompany/sendNotice/COMMITQUESTIONNOTICE`, params)
    .then(res => res.data);
};
// pdf下载
export const downPDFurl = params => {
  return post(`${basePlat}/model/creatTestReportPdf/${params.token}`, params)
    .then(res => res.data);
};

// 解锁报告
export const unlockReport = ({
  nsrsbh,
  tag,
  token,
  userId,
  uuid
}) => {
  return post(`${basePlat}/cd/exchange/unlocking`, {
      nsrsbh,
      tag,
      token,
      userId,
      uuid
    }, false)
    .then(res => res.data);
};

// 更新总结
export const addSummaryByTag = ({
  nsrsbh,
  summary,
  tag,
  userId,
  uuid
}) => {
  return post(`${basePlat}/pdfData/addSummaryByTag`, {
      nsrsbh,
      summary,
      tag,
      userId,
      uuid
    })
    .then(res => res.data);
};


// 更新综述
export const updateZS = ({
  contrastKey,
  dataText,
  id,
  orderNumber,
  pdfData,
  sort,
  titleName
}) => {
  return post(`${basePlat}/pdfData/update`, {
      contrastKey,
      dataText,
      id,
      orderNumber,
      pdfData,
      sort,
      titleName
    })
    .then(res => res.data);
};


//获取所有产品及规格
export const requestTableInfo = async params => {
  const url = `${basePlat}/boGoodsEdition/findAllEdition`;
  const res = await post(url, {
    ...params
  });
  return res.data;
};

//获取已购买版本接口
export const findBuyEdition = async ({
  token
}) => {
  const url = `${basePlat}/boGoodsEdition/findBuyEdition`;
  const res = await get(url, {
    token
  });
  return res.data;
};

//此接口为用户选择征期后点击下一步时需要调用的校验接口，主要校验内容为：用户是否已认证，是否已购买商品等，只有通过校验后才可以进行测评
export const checkProductPermission = async (token, goodsId, type = null) => {
  const url = `${basePlat}/model/checkAuth/${token}`;
  const res = await post(url, {
    ...{
      goodsId,
      type
    }
  });
  return res.data;
};

//确认用户上传报表信息
export const confirmUploadedTable = async params => {
  if (params) {
    params.goodsId = params.goodsId.toString();
  }
  const url = `${basePlat}/model/checkUserData`;
  const res = await post(url, {
    ...params
  });
  return res.data;
};

//获取uuid
export const getUUID = async () => {
  const url = `${basePlat}/common/getUuid`;
  const res = await get(url);
  return res.data;
};

/**
 * 生成测评结果页
 * @param params
 * @returns {Promise<unknown>}
 */
export const genTestReport = async params => {
  const url = `${basePlat}/model/check`;
  const {
    bankId,
    goodsId,
    kjzd,
    token,
    nsrsbh,
    tag,
    id,
    uuid,
    zq,
    invoiceNsrsbh,
    invoice_email,
    invoiceCompanyName,
    invoice_phone,
    isAutoData,
    getDataWay,
    appId = null,
    deleteDataType,
    nameless = false,
    taxRateCalculation
  } = params;


  const res = await post(url, {
    bankId,
    goodsId,
    kjzd,
    token,
    nsrsbh,
    tag,
    id,
    uuid,
    zq,
    invoiceNsrsbh,
    invoiceCompanyName,
    invoice_email,
    invoice_phone,
    isAutoData,
    getDataWay,
    appId,
    deleteDataType,
    nameless,
    taxRateCalculation
  });
  return res.data;
};

//发票文本与数据接口接口

export const getInvoiceBillData = async params => {
  const url = `${basePlat}/model/getInvoiceBillData`;
  const {
    nsrsbh,
    uuid,
    zq,
    bankId
  } = params;
  const res = await get(url, {
    nsrsbh,
    uuid,
    zq,
    bankId
  });
  return res.data;
};

//获取uuid
export const getReportResult = async (uuid, token, bankId, nsrsbh, zq) => {
  const url = `${basePlat}/model/getResult/${token}`;
  const res = await post(url, {
    uuid,
    token,
    bankId,
    nsrsbh,
    zq
  });
  return res.data;
};

//获取uuid
export const getVisualReport = async (programId, desensitizationStatus) => {
  const url = `${basePlat}/pdfData/getVisualReport`;
  const res = await get(url, {
    programId,
    desensitizationStatus
  });
  return res.data;
};

//获取检测记录
export const getHistoryReport = async nsrsbh => {
  const url = `${basePlat}/model/getRuleList`;
  const res = await post(url, {
    nsrsbh
  });
  return res.data;
};

//获取检测记录
export const getHistoryReports = async (
  nsrsbh,
  companyName,
  startDate,
  endDate,
  pageNo,
  pageSize,
  tag,
  status,
  goodsId
) => {
  const url = `${basePlat}/model/getRuleListTable`;
  const res = await post(url, {
    nsrsbh,
    companyName,
    startDate,
    endDate,
    pageNo,
    pageSize,
    tag,
    status,
    goodsId
  });
  return res.data;
};





//api base
export const getAPIBase = () => {
  return basePlat;
};

//提交图片给后端
export const sendPic2OSS = async params => {
  const {
    gxzbbdl,
    sszb,
    fxdj,
    gxzbcyl,
    cwzb,
    nsrsbh,
    uuid,
    gauge
  } = params;
  const url = `${basePlat}/common/uploadBASEToOSS`;
  const res = await post(url, {
    gxzbbdl,
    sszb,
    fxdj,
    gxzbcyl,
    cwzb,
    nsrsbh,
    uuid,
    gauge
  });
  return res.data;
};

// 异常纳税人搜索
export const nsrSearch = params => {
  return get(
      `${basePlat}/xxk/ycnsr/hz/` +
      params.token +
      "?nsrxx=" +
      params.nsrxx +
      "&pageNo=" +
      params.pageNo +
      "&pageSize=" +
      params.pageSize
    )
    .then(res => res.data);
};

// 产品中心

// 创建产品订单
// export const createOrder = params => {
//   return post(`${basePlat}/bBuyOrder/create?token=${params.token}`, params).then(res => res.data);
// };

/**
 * @template T
 * @typedef api.Responce
 * @property {string} message
 * @property {number} code
 * @property {T} data
 */

/**
 * @typedef {Object} api.createOrderRequestParams
 * @property {number} policyId - 必须
 * @property {number} goodsCount - 必须
 * @property {string} channelUniqueId - 必须
 * @property {?string} posterUniqueId - 非必须
 * @property {?number} marketingStrategyId - 非必须
 */
/**
 *
 * @param {api.createOrderRequestParams} params
 * @returns {Promise<api.Responce<string>>}
 */
export function createOrder(params){
  return post(
    `${base}/channel/channelOrdOrder/createOrder`, params)
    .then(res => res.data);
}

///channelOrdOrder/cancelOrder
// export function cancelOrder(){
//
// }


// 掉起支付接口
export const openOrder = params => {
  return post(`${basePlat}/bBuyOrder/createCharge`, params).then(res => res.data);
};
// 查找所有优惠券
export const allFull = params => {
  return get(
      `${basePlat}/bBuyOrder/selectAllCouponInfoBefore?token=${params.token}&sPageNo=${params.sPageNo}&sPageSize=${params.sPageSize}`
    )
    .then(res => res.data);
};
// 查询用户领取优惠券
export const searchFull = params => {
  return get(`${basePlat}/bBuyOrder/selectUserReceiveCoupon?token=${params.token}`)
    .then(res => res.data);
};
// 用户领取优惠券
export const getFull = params => {
  return get(
      `${basePlat}/bBuyOrder/userReceiveCoupon?token=${params.token}&couponid=${params.id}`)
    .then(res => res.data);
};
// 用户使用优惠券
export const useFull = params => {
  return get(
      `${basePlat}/bBuyOrder/userapplyCoupon?token=${params.token}&couponid=${params.couponid}&specid=${params.specid}`
    )
    .then(res => res.data);
};
// 已购买
export const alreadyBuy = params => {
  return get(`${basePlat}/bBuyOrder/alreadyPurchased?token=${params.token}`)
    .then(res => res.data);
};
// 获取所有商品
export const allGoods = params => {
  return post(`${basePlat}/boGoods/loadByPage`, params).then(res => res.data);
};
// 查询订单记录
export const orderRecord = params => {
  return post(`${basePlat}/bBuyOrder/myOrderRecord?token=${params.token}`, params)
    .then(res => res.data);
};
// 满减券
export const fullSelect = params => {
  return get(
      `${basePlat}/bBuyOrder/selectUserMaUseCoupon?token=${params.token}&specId=${params.specId}`)
    .then(res => res.data);
};

// 地区
export const areaList = params => {
  return get(`${basePlat}/dmDq/loadAreaTree`)
    .then(res => res.data);
};

// 查询用户多个可使用优惠券
export const someFull = params => {
  return post(`${basePlat}/bBuyOrder/selectAllUserMaUseCoupon`, params).then(res => res.data);
};

//查找已购买版本
export const getPurchasedVersions = async params => {
  const {
    token
  } = params;
  const url = `${basePlat}/bBuyOrder/selectPurchasedVersion?token=${token}`;
  const res = await get(url);
  return res.data;
};

// 已购买风险库
export const alreadyBuyItem = params => {
  return get(`${basePlat}/bBuyOrder/alreadyPurchasedRiskLibrary?token=${params.token}`)
    .then(res => res.data);
};

// 查看所有版本
export const getVersion = params => {
  return post(`${basePlat}/boGoodsEdition/findAllEdition`, params).then(res => res.data);
};

// 订单记录模块
// 查询所有订单记录
export const allRecord = params => {
  return post(`${basePlat}/bBuyOrder/myOrderRecord?token=${params.token}`, params)
    .then(res => res.data);
};
/**
 * 取消订单
 * @param {{orderNo:string}} params
 * @returns {Promise<unknown>}
 */
export const cancelOrder = params => {
  return get(`${base}/channel/channelOrdOrder/cancelOrder`,params).then(res => res.data);
};

// export const cancelOrder = params => {
//   return get(`${basePlat}/bBuyOrder/closeOrder?orderId=${params.orderId}`).then(res => res.data);
// };
// 查看单个详情订单
export const viewOrder = params => {
  return get(`${basePlat}/bBuyOrder/loadOrder?orderNo=${params.orderNo}`).then(res => res.data);
};
// 创建支付订单
// export const createOrder = params => { return post(`${base}/bBuyOrder/create?token=${params.token}`, params).then(res => res.data); }
// 查看已购买版本
export const buyItem = params => {
  return get(`${basePlat}/bBuyOrder/selectPurchasedVersion`, params)
    .then(res => res.data);
};

// 查看支付状态
export const findOrderPayStatus = params => {
  return get(
      `${basePlat}/bBuyOrder/findOrderPayStatus?orderNo=${params.orderNo}`
    )
    .then(res => res.data);
};

// 查看版本次数
export const getCountGoodsTimes = params => {
  return post(`${basePlat}/home/countGoodsTimes`, params).then(res => res.data);
};

// 兑换记录
export const agentRecord = params => {
  return post(`${basePlat}/cdKeyPlat/getCdKeyList`, params).then(res => res.data);
};

// 查看版本介绍
export const findEdition = params => {
  return post(`${basePlat}/boGoodsEdition/findAllIntroductionByEdition`, params).then(res => res
    .data);
};

// 检测测试是否可以解锁
// export const removeMaskDetection = async (uuid, token, bankId, nsrsbh, zq) => {
//   const url = `${base}/model/removeMaskDetection/${token}`;
//   const res = await post(url, { uuid, token, bankId, nsrsbh, zq });
//   return res.data;
// };

// 解锁检测过的测试
export const unlockHistoryTest = async (uuid, token, bankId, nsrsbh, zq, tag) => {
  const url = `${basePlat}/model/reductionTimes/${token}`;
  const res = await post(url, {
    uuid,
    token,
    bankId,
    nsrsbh,
    zq,
    tag
  });
  return res.data;
};

// 兑换券
export const exchange = async (token, cdk) => {
  const url = `${basePlat}/cd/exchange/act`;
  const res = await post(url, {
    token,
    cdk
  });
  return res.data;
};

// 用户使用切换优惠券
export const useReduce = params => {
  return post(`${basePlat}/bBuyOrder/userapplyCoupon`, params).then(res => res.data);
};

// 代理商配置
export const getAgent = params => {
  return get(`${basePlat}/logo/getLogoTable/${params.token}`).then(res => res.data.data);
};

// 自动匹配风险库
export const mateGoods = params => {
  return get(`${basePlat}/boGoods/getnewGoodsInfo`, params)
    .then(res => res.data);
};

// 删除报告
export const deleteRule = params => {
  return post(`${basePlat}/model/deleteRuleListOne`, params).then(res => res.data);
};
/**
 * 授权
 * @param params
 * @param {"plat"|"daqc"} prefix
 * @returns {Promise<unknown>}
 */
export const useAuthorize = (params, prefix = "plat") => {
  return post(`${base}/${prefix}/breeze/breezeAuthorization`, params).then(res => res.data);
};

// 企业信息校验  /bCompany/checkCompanyInfo
export const checkCompany = params => {
  return post(`${basePlat}/bCompany/checkCompanyInfo`, params).then(res => res.data);
};

// 发票自动取数新街口
export const mateBill = params => {
  return get(
      `${basePlat}/breeze/invoice/isAutoInvoiceBillData?invoice_nsrsbh=${params.invoice_nsrsbh}&isAutoData=${params.isAutoData}`,
      params
    )
    .then(res => res.data);
};

// 自动匹配行业新接口
export const checkNew = params => {
  return post(`${basePlat}/model/check3`, params).then(res => res.data);
};

// 补救接口
export const retryMethods = params => {
  return post(`${basePlat}/model/checkRetry`, params).then(res => res.data);
};

/**
 * 获取省份
 * @param params
 * @param {"plat"|"daqc"} prefix
 * @returns {Promise<unknown>}
 */
export const getProvince = (params, prefix = "plat") => {
  return post(`${base}/${prefix}/breeze/getProvince`, params, false).then(res => res.data);
};

/**
 * 获取批次号
 * @param params
 * @param {"plat"|"daqc"} prefix
 * @returns {Promise<unknown>}
 */
export const getProvinceBath = (params, prefix = "plat") => {
  return post(`${base}/${prefix}/breeze/getBatchNo`, params).then(res => res.data);
};

/**
 * 获取授权人员
 * @param params
 * @param {"plat"|"daqc"} prefix
 * @returns {Promise<unknown>}
 */
export const getIdentityInfo = (params, prefix = "plat") => {
  return post(`${base}/${prefix}/breeze/getIdentityInfo`, params, false).then(res => res.data);
};

/**
 * 获取二维码
 * @param params
 * @param {"plat"|"daqc"} prefix
 * @returns {Promise<unknown>}
 */
export const getQrCode = (params, prefix = "plat") => {
  return post(`${base}/${prefix}/breeze/getQrCode`, params).then(res => res.data);
};

// 其他省份授权
export const breezeOuter = (params, prefix = "plat") => {
  return post(`${base}/${prefix}/breeze/breezeAuthorizationByCode`, params, false).then(res => res
    .data);
};

/**
 * 获取验证码
 * @param params
 * @param {"plat"|"daqc"} prefix
 * @returns {Promise<unknown>}
 */
export const breezeCode = (params, prefix = "plat") => {
  return post(`${base}/${prefix}/breeze/sendVerificationCode`, params).then(res => res.data);
};

// AI事务所查看余额表上传情况
export const getBalanceInfo = params => {
  return post(`${basePlat}/balance/checkUpload`, params).then(res => res.data);
};

// 事务所查看余额表明细
export const showBalanceDetail = params => {
  return post(`${basePlat}/balance/sheet`, params).then(res => res.data);
};

//删除单个文件
// export const deleteTable = async (nsrsbh, zq, templateId, token) => {
//   const url = `${base}/model/cancel/upload/${token}`;
//   const res = await post(url, {
//     nsrsbh,
//     zq,
//     template: templateId
//   });
//   return res.data;
// };

//   首页检测分数和优惠政策条数
export const indexScoresNum = params => {
  return post(`${basePlat}/home/getLastCheck`, params).then(res => res.data);
};

//   首页各版本信息
export const indexVersionNum = params => {
  return post(`${basePlat}/home/countGoodsTimes?token=${params.token}`, params)
    .then(res => res.data);
};

// 获取行业编码
export const getTradeList = params => {
  return get(`${basePlat}/codeUtil/codeHy`, params).then(res => res.data);
};

// 查询取数进度/breeze/progressBar
export const progressBar = params => {
  return post(`${basePlat}/breeze/progressBar`, params).then(res => res.data);
};

//   addReportValue
export const addReportValue = params => {
  return get(`${basePlat}/model/addReportValue`, params)
    .then(res => res.data);
};

//确认用户上传报表信息
export const addContactMsg = async params => {
  const url = `${base}/biz-admin/userMessage/add`;
  const res = await post(url, {
    ...params
  });
  return res.data;
};


// // 查看基础信息表详情
// export const getInfoByCompanyNsrsbm = params => {
//   return get(`${basePlat}/projectInfo/getInfoByCompanyNsrsbm?nsrsbm=${params.nsrsbm}`)
//     .then(res => res.data);
// };

// 新增基础信息表
export const addInfoBy = params => {
  return post(`${basePlat}/projectInfo/add`, params)
    .then(res => res.data);
};

// 修改基础信息表
// export const editInfoBy = params => {
//   return post(`${basePlat}/projectInfo/edit`, params)
//     .then(res => res.data);
// };

// 创建订单
export const createBuyOrder = params => {
  return post(`${basePlat}/bBuyOrder/create`, params).then(res => res.data);
};

// 查看原因
export const getCheckReason = params => {
  return get(`${basePlat}/model/getCheckReason?uuid=${params.uuid}&nsrsbh=${params.nsrsbh}`).then(
    res => res.data);
};

// 创建ping++订单
export const createPing = params => {
  return post(`${basePlat}/bBuyOrder/createCharge`, params).then(res => res.data);
};

// 查看单个商品
export const getProductById = async ({
  productId
}) => {

  const url = `${basePlat}/boGoodsEdition/loadOne`;
  const res = await get(url, {
    id: productId
  });
  return res.data;
}

//是否已经上传必要表格
export const isFindNeedExcel = async (uuid, zq) => {
  const url = `${basePlat}/common/findNeedExcel?uuid=${uuid}&zq=${zq}`;
  const res = await get(url);
  return res.data;
};


/**
 * 是否已经上传必要表格2(带提示版)
 * @param {string} uuid
 * @param {number} zq
 * @param {number} tag
 * @param {string} nsrsbm
 * @param {string} taxNo
 * @returns {Promise<*>}
 */
export const isFindNeedExcel2 = async (uuid, zq, tag, nsrsbm, taxNo, autoWarning = false) => {
  const url =
    `${basePlat}/common/findNeedExcel2?uuid=${uuid}&zq=${zq}&tag=${tag}&nsrsbm=${nsrsbm}&taxNo=${taxNo}`;
  const res = await get(url, {}, autoWarning);
  return res.data;
};

/**
 * 推荐上传的表
 * @param {string} uuid
 * @param {number} zq
 * @param {number} tag
 * @param {string} nsrsbm
 * @param {string} taxNo
 * @returns {Promise<*>}
 */
export const isFindNeedExcel3 = async (uuid, zq, tag, nsrsbm, taxNo) => {
  const url =
    `${basePlat}/common/findNeedExcel3?uuid=${uuid}&zq=${zq}&tag=${tag}&nsrsbm=${nsrsbm}&taxNo=${taxNo}`;
  const res = await get(url);
  return res.data;
};


//查询当前日期是否已经进行汇算清缴接口
export const findFinalSettlementCondition = async (zq) => {
  const url = `${basePlat}/common/findFinalSettlementCondition?zq=${zq}`;
  const res = await get(url);
  return res.data;
};

//是否在自动取数已经授权
export const findTaxAutoStatusByUuid = async ({
  uuid,
  nsrsbm
}) => {
  const url = `${basePlat}/common/findTaxAutoStatusByUuid`;
  const res = await get(url, {
    uuid,
    nsrsbm
  });
  return res.data;
};


//根据id查找产品
export const boGoodsEditionLoadOne = async ({
  id
}) => {
  const url = `${basePlat}/boGoodsEdition/loadOne`;
  const res = await get(url, {
    id
  });
  return res.data;
};

// 查看所有版本
export const getBoGoodsEdition = params => {
  return get(`${basePlat}/boGoodsEdition/loadAll`, params)
    .then(res => res.data);
};

// 获取logo和公司名称
export const getLogoAndInfo = params => {
  return get(`${basePlat}/openClient/getInfoByUrl`, params)
    .then(res => res.data);
};

// 根据版本ID获得可用征期类型
export const getAvailableZqTypesByEdition = async ({
  edition
}) => {
  const url = `${basePlat}/boEditionBankCentre/getListByEditionId`;
  const res = await get(url, {
    edition
  });
  return res.data;
};

/**
 * 检测可视化报告分享功能
 * @param {String} uuid
 * @param {String} checkCode
 * @param {String} token
 * @returns {Promise<*>}
 */
export const checkShareCode = async (uuid, checkCode, token) => {
  const url = `${basePlat}/share/checkShareCode`;
  const res = await get(url, {
    uuid,
    checkCode,
    token
  });
  return res.data;
};
/**
 * 检测分享链接是否已经被使用
 * @param urlCheck
 * @returns {Promise<*>}
 */
export const checkShareUrl = async (urlCheck) => {
  const url = `${basePlat}/share/checkUrl`;
  const res = await get(url, {
    urlCheck
  });
  return res.data;
};
/**
 * 创建可视化报告分享功能
 * @param uuid
 * @param nsrsbm
 * @param expireHours
 * @returns {Promise<*>}
 */
export const createShareCode = async (uuid, nsrsbm, expireHours) => {
  const url = `${basePlat}/share/createShareCode`;
  const res = await get(url, {
    uuid,
    nsrsbm,
    hours: expireHours
  });
  return res.data;
};

/**
 * 获取公司风险详细信息
 * @returns {Promise<*>}
 */


export const getCompanyRiskDetails = async ({
  companyArea,
  companyName,
  pageNum,
  pageSize,
  programId,
  riskList,
  companyType,
  type,
  desensitized,
  startMoney,
  endMoney,
  startDate,
  endDate
}) => {
  const url = `${basePlat}/pdfData/getCompanyRiskDetails`;
  const res = await post(url, {
    companyArea,
    companyName,
    pageNum,
    pageSize,
    programId,
    riskList,
    companyType,
    type,
    desensitized,
    startMoney,
    endMoney,
    startDate,
    endDate
  });
  return res.data;
};

// 获取可视化报告地区
export const getVisualArea = async (programId, companyType) => {
  const url = `${basePlat}/pdfData/getVisualArea`;
  const res = await get(url, {
    programId,
    companyType
  });
  return res.data;
};

// 获取可视化报告风险下拉选项
export const getVisualRiskOptions = async (programId, companyType) => {
  const url = `${basePlat}/pdfData/getVisualRiskModel`;
  const res = await get(url, {
    programId,
    companyType
  });
  return res.data;
};

// 获取可视化报告风险
export const getVisualRisk = async () => {
  const url = `${basePlat}/pdfData/getVisualRisk`;
  const res = await get(url);
  return res.data;
};

/**
 * 获取用户的脱敏设置
 * @param {String} token
 * @returns {Promise<*>}
 */
export const getDesensitizationInfo = async ({
  token
}) => {
  const url = `${basePlat}/bCompanyUser/getInfo`;
  const res = await get(url, {
    token
  });
  return res.data;
};

/**
 * 获取用户的脱敏设置
 * @param {String} token
 * @param {Number} desensitizationStatus
 * @returns {Promise<*>}
 */
export const updateDesensitizationInfo = async ({
  token,
  desensitizationStatus
}) => {
  const url = `${basePlat}/bCompanyUser/updateInfo`;
  const res = await get(url, {
    token,
    desensitizationStatus
  });
  return res.data;
};

/**
 * 检测脱敏报告是否可用
 * @param uuid
 * @returns {Promise<*>}
 */
export const getDesensitizationStatus = async ({
  uuid
}) => {
  const url = `${basePlat}//pdfData/getDesensitizationStatus`;
  const res = await get(url, {
    programId: uuid
  });
  return res.data;
};

export const getDueDiligenceModel001 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel001`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}

export const getDueDiligenceModel002 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel002`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}

export const getDueDiligenceModel003 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel003`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}
export const getDueDiligenceModel004 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel004`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}
export const getDueDiligenceModel005 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel005`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}

export const getDueDiligenceModel006 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel006`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}
export const getResultBasicInfo = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultBasicInfo`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}
export const getDueDiligenceModel007 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel007`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}

export const getDueDiligenceModel008 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel008`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}
export const getDueDiligenceModel009 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel009`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}
export const getDueDiligenceModel010 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel010`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}
export const getDueDiligenceModel011 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel011`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}
export const getDueDiligenceModel012 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel012`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}
export const getDueDiligenceModel013 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel013`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}
export const getDueDiligenceModel014 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel014`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}
//通过税号查询企业信息
export const getCompanyMsgByTaxNo = async ({
  taxNo
}) => {
  const url = `${basePlat}/breeze/getCompanyMsgByTaxNo`;
  const res = await get(url, {
    taxNo
  });
  return res.data;
}


/**
 * 获取税局是否已经对接
 * @param {{taxNo:String}}taxNo
 * @returns {Promise<*>}
 */
export const getTaxAreaBureauServiceInfo = async ({
  taxNo
}) => {
  const url = `${basePlat}/breeze/getAreaMsg`;
  const res = await get(url, {
    taxNo
  });
  return res.data;
}

// 三年报告
// 查询进项公司
export const getEntryCompanyList = params => {
  return axios
    .post(`${basePlat}/invoiceTotal/entry/getCompanyList`, params)
    .then(res => res.data);
};
// 查询销项公司
export const getSalesCompanyList = params => {
  return axios
    .post(`${basePlat}/invoiceTotal/sales/getCompanyList`, params)
    .then(res => res.data);
};
// 查询进项商品
export const getEntryInvoiceGoodsList = params => {
  return axios
    .post(`${basePlat}/invoiceTotal/entry/getInvoiceGoodsList`, params)
    .then(res => res.data);
};
// 查询销项商品
export const getSalesInvoiceGoodsList = params => {
  return axios
    .post(`${basePlat}/invoiceTotal/sales/getInvoiceGoodsList`, params)
    .then(res => res.data);
};
// 查询进项品类
export const getEntryInvoiceCategoryList = params => {
  return axios
    .post(`${basePlat}/invoiceTotal/entry/getInvoiceCategoryList`, params)
    .then(res => res.data);
};
// 查询销项品类
export const getSalesInvoiceCategoryList = params => {
  return axios
    .post(`${basePlat}/invoiceTotal/sales/getInvoiceCategoryList`, params)
    .then(res => res.data);
};
// 查询进项发票
export const getEntryInvoiceList = params => {
  return axios
    .post(`${basePlat}/invoiceTotal/entry/getInvoiceList`, params)
    .then(res => res.data);
};
// 查询销项发票
export const getSalesInvoiceList = params => {
  return axios
    .post(`${basePlat}/invoiceTotal/sales/getInvoiceList`, params)
    .then(res => res.data);
};
// 查询进项每月进项
export const getEntryInvoiceMonthList = params => {
  return axios
    .post(`${basePlat}/invoiceTotal/entry/getInvoiceMonthList`, params)
    .then(res => res.data);
};
// 查询销项每月进项
export const getSalesInvoiceMonthList = params => {
  return axios
    .post(`${basePlat}/invoiceTotal/sales/getInvoiceMonthList`, params)
    .then(res => res.data);
};


// 报表查询表格对比
export const reportCompare = (uuid, type, zq) => {
  return axios
    .get(
      `${base}/admin/reportCompare/getList?programId=${uuid}&tableName=${type}&zq=${zq}`
    )
    .then(res => res.data);
};

export const reportCompareCw = (uuid, zq) => {
  return axios
    .get(
      `${base}/admin/reportCompare/getTaxReportAnalysis?programId=${uuid}&zq=${zq}`
    )
    .then(res => res.data);
};

// 重新生成报告
export const longPeriodFiscalRetry = (programId) => {
  return axios
    .get(`${basePlat}/longPeriodFiscal/retry?programId=${programId}`)
    .then(res => res.data);
};

// 删除发票
export const deleteBill = (programId) => {
  return axios
    .get(`${basePlat}/invoiceTotal/deleteInvoiceSection?programId=${programId}`)
    .then(res => res.data);
};
// 报告样式
export const getPageStyle = ({programId}) => {
  return axios
    .get(`${basePlat}/model/getPageStyle?programId=${programId}`)
    .then(res => res.data);
};

export const getDataDeleteText = (programId) => {
  return axios
    .get(`${basePlat}/longPeriodFiscal/getDataDeleteText?programId=${programId}`)
    .then(res => res.data);
};

export const getDataDeleteText2 = (programId) => {
  return axios
    .get(`${basePlat}/longPeriodFiscal/getDataDeleteText2?programId=${programId}`)
    .then(res => res.data);
};
//导出pdf表格
export const exportTableApi = (programId, zq) => {
  return axios
    .get(
      `${base}/admin/reportCompare/exportReportExcel?programId=${programId}&zq=${zq}`
    )
    .then(res => res.data);
};

// 202212需求  数据错误弹窗提示
export const getInvoiceAccess = programId => {
  return get(`${basePlat}/invoiceTotal/getInvoiceAccess?uuid=${programId}`)
    .then(res => res.data);
};


// 20230216新增
// 进项分类查询
export const getEntryClass = taxNo => {
  return get(`${basePlat}/invoiceTotal/entry/getInvoiceTaxCodeList?taxNo=${taxNo}`)
    .then(res => res.data);
};
// 销项分类查询
export const getSalesClass = taxNo => {
  return get(`${basePlat}/invoiceTotal/sales/getInvoiceTaxCodeList?taxNo=${taxNo}`)
    .then(res => res.data);
};

export const getInvoiceDetailPage = params => {
  return post(`${basePlat}/invoice/detail/getInvoiceDetailPage`, params)
    .then(res => res.data);
};


export const getInvoiceDetailCompanyList = params => {
  return post(`${basePlat}/invoice/detail/getInvoiceDetailCompanyList`, params)
    .then(res => res.data);
};

export const getInvoiceYearBox = (programId, modelId) => {
  return get(
    `${basePlat}/invoice/detail/getInvoiceYearBox?programId=${programId}&modelId=${modelId}`)
    .then(res => res.data);
};

export const getInvoiceType = (programId, modelId) => {
  return get(
    `${basePlat}/invoice/detail/getInvoiceType?programId=${programId}&modelId=${modelId}`)
    .then(res => res.data);
};

//获取最新通知

export const getLatestNotice = () => {
  return get(
    `${basePlat}/bizNotice/getLatestNotice`)
    .then(res => res.data);
};

//不再提醒通知
export const noPrompt = (noticeId) => {
  return post(
    `${basePlat}/bizNotice/noPrompt?noticeId=${noticeId}`)
    .then(res => res.data);
};

//获取通知分页
export const getNoticePage = (params) => {
  return post(
    `${basePlat}/bizNotice/getPage`, params)
    .then(res => res.data);
};
//通知已读状态修改
export const markAsRead = (params) => {
  return post(
    `${basePlat}/bizNotice/markAsRead`, params)
    .then(res => res.data);
};
//获得未读通知条数
export const getUnreadNum = () => {
  return get(
    `${basePlat}/bizNotice/getUnreadNum`)
    .then(res => res.data);
};

// 修改基础信息表
export const editInfoBy = params => {
  return post(`${base}/tbdc/projectInfo/edit`, params)
    .then(res => res.data);
};

// 查看基础信息表详情
export const getInfoByCompanyNsrsbm = programId => {
  return get(`${base}/tbdc/projectInfo/getProjectInfoFDC?programId=${programId}`)
    .then(res => res.data);
};

//通过二维码统一加密参数获取二维码对应渠道（推广海报）参数
export const getChannelUnifiedParam = unifiedParamId => {
  return get(`${base}/channel/auth/getChannelUnifiedParam?unifiedParamId=${unifiedParamId}`)
    .then(res => res.data);
};


//h5扫码记录接口
export const scanRecord = unifiedParamId => {
  return get(`${base}/channel/auth/scanRecord?unifiedParamId=${unifiedParamId}`)
    .then(res => res.data);
};


// 加密
export const authAESEncrypt = (params) => {
  return post(
    `${base}/daqc/thirdAuth/authAESEncrypt`, params)
    .then(res => res.data);
};


// 创建取数页面信息存储
export const bThirdFetchInfo = (params) => {
  return post(
    `${base}/daqc/bThirdFetchInfo/add`, params)
    .then(res => res.data);
};

//获取分享二维码
export const getSharedQrCode = (url,programId) => {
  // return get(`${base}/shuian-home/goods/getSharedQrCode?url=${url}`).then(res => res.data);
  return get(`${ base }/daqc/auth/createSharedQrCode?url=${ url }&programId=${programId}`).then(res => res.data);

}


// 2024-04-16发起检测

export const startTesting = (params) => {
  return post(`${base}/shuian-home/testing/risk/v2/startTesting`,params).then(res => res.data);
}

// 获取当前检测流程状态
export const getTestingProcessGraphic = (programId) => {
  return get(`${base}/shuian-home/testing/risk/v2/getTestingProcessGraphic?programId=${programId}`).then(res => res.data);
}

// 查询检测报告是否还存在，分享链接用
export const getLoginStatus = (params) => {
  return get(
    `${base}/daqc/sunan/auth/getLoginStatus`, params)
    .then(res => res.data);
};
