// import moment from "moment";
import nzhcn from "nzh/cn";
import {isNumber} from "lodash";

export const isNumeric = str => {
  if (typeof str === "number") {
    return true;
  } else if (typeof str === "string") {
    if (str.indexOf(",") >= 0) {
      //包含千分号
      const thousandSeperatorPreg = /^[-+]?[\d]{0,3}(?=(,[\d]{3})*(\.[\d]{0,2})?$)/;
      return thousandSeperatorPreg.test("1,234.00");
    } else {
      //没有千分号
      return /^[-+]?[\d]+(\.[\d]{0,2})?$/.test(str);
    }
  }
};


/**
 * 格式化为金额
 * @param num
 */
export function decimal(num) {
  if (num == null) {
    return ''
  }

  return toFixed(num, 2)

}

export const parseNumber = str => {
  if (typeof str === "number") {
    return str;
  }
  if (isNumeric(str)) {
    return parseFloat(str.replace(/,/g, ""));
  } else {
    return str;
  }
};

export function round(num, point = 2) {
  if (parseFloat(num) == num) {
    num = parseFloat(num)
    return parseFloat(num.toFixed(point));
  } else {
    return 0
  }

}

export function toFixed(num, point = 2) {
  return Number.parseFloat(num).toFixed(point)
}


export function percentage(num, point = 2) {
  return (Math.round(num * 100 * 10000) / 10000).toFixed(point) + "%";
}

export function dateRange(fromDate, toDate) {
  return fromDate == toDate ? fromDate : `${fromDate} - ${toDate}`;
}



/**
 * 将数字转换为金额格式
 * @param value
 * @returns {string}
 */
export function formatToDecimal(value) {
  if (isNaN(value)||value==null){
    return ''
  }
  const isANumber = isNumber(value);

  if (isANumber) {
    value = parseFloat(value)
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })



  } else {
    return value;
  }

}

/**
 * 格式化文本
 * @param text
 * @returns {*}
 */
export function formatText(text) {
  if (!text) {
    return "";
  }
  text = text.replace(/[\r]?([^\n]+)/g, '<div>$1</div>')
  return text
}

/**
 * 把阿拉伯数字转成中文
 * @param number
 * @returns {*}
 */
export function chineseNumber(number) {
  return nzhcn.encodeS(number);
}

/**
 * 把数字不足2位的左补0
 */
export function padNumber(number, length = 2) {
  return number == null ? '' : number.toString().padStart(length, '0')
}
