<script>
export default {
  name: "AgreementText"
}
</script>

<template>
  <div class=WordSection1>

    <p class=MsoNormal style='line-height:150%'><span lang=EN-US>                                                                                                                                                                                                                                                                                     
</span></p>

    <p class=MsoBodyText align=center style='text-align:center;line-height:150%'><b><span
      style='font-size:16.0pt;line-height:150%;color:#0C0C0C;letter-spacing:-.05pt'>智能财税服务平台服务协议</span></b>
    </p>

    <p class=MsoBodyText align=center style='text-align:center;line-height:150%'><b><span
      style='font-size:11.0pt;line-height:150%;letter-spacing:-.1pt'>特</span></b><b><span
      style='font-size:11.0pt;line-height:150%;letter-spacing:-.05pt'>别提醒</span></b></p>

    <p class=MsoNormal style='line-height:150%'><span lang=EN-US>&nbsp;</span></p>

    <p class=MsoBodyText style='line-height:
150%'><span style='letter-spacing:.6pt'>本服务协议（下称<span lang=EN-US>“</span>本协议<span
      lang=EN-US>”</span>）</span><span
      style='letter-spacing:.7pt'>是税安科技（杭州）有限公司</span><span
      lang=EN-US style='letter-spacing:.45pt'>(</span><span style='letter-spacing:
.45pt'>以下统称<span lang=EN-US>“</span>税安科技<span lang=EN-US>”</span>或<span
      lang=EN-US>“</span>我们<span lang=EN-US>”)</span>与您（以下或称<span
      lang=EN-US>“</span>用户<span
      lang=EN-US>”</span>）</span><span style='letter-spacing:.6pt'>就税安科技产品</span><span
      style='letter-spacing:.45pt'>的相关事项所订立的有效合约。</span></p>

    <p class=MsoBodyText style='line-height:
150%'><span style='letter-spacing:.65pt'>在接受本协议之前，请您务必审慎阅读、</span><span
      style='letter-spacing:.7pt'>充分理解本</span><span
      style='letter-spacing:.65pt'>协议各条款的内容，特别是免除</span><span
      style='letter-spacing:.6pt'>或者限制责任的条款、</span><span style='letter-spacing:.7pt'>法律适用和争议解决</span><span
      style='letter-spacing:.65pt'>条款以及其他涉及您重大权益影响的条款，这</span><span style='letter-spacing:
.5pt'>些条款将以粗体标识，您应重点阅读。如果您对本协议的条款有</span><span
      style='letter-spacing:.45pt'>疑问的，可向税安科技咨询，</span><span
      style='letter-spacing:.5pt'>我们将向您解释该疑问</span><span
      style='letter-spacing:.45pt'>条款。</span></p>

    <p class=MsoBodyText align=center style='text-align:center;
line-height:150%'><b><span lang=EN-US style='letter-spacing:.5pt'>通用</span><span
      lang=EN-US style='letter-spacing:.4pt'>条款</span></b></p>

    <p class=MsoNormal style='line-height:150%'><span lang=EN-US>&nbsp;</span></p>

    <p class=MsoBodyText style='line-height:
150%'><b><span lang=EN-US style='letter-spacing:-1.05pt'>1<span
      style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
      lang=EN-US style='letter-spacing:.25pt'>总则</span></b></p>

    <p class=MsoBodyText style='line-height:
150%'><span lang=EN-US style='letter-spacing:-.6pt'>1.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-US style='letter-spacing:.4pt'>签约动作及协议范围</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-1.05pt'>1.1.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='letter-spacing:.2pt'>您自行或委托服务商通过税安科技官方网站</span></b><b><span
      style='letter-spacing:.7pt'> </span></b><b><span lang=EN-US style='letter-spacing:
.15pt'>www.ai-tax.cn</span></b><b><span style='letter-spacing:.15pt'>（以下简称<span
      lang=EN-US>“</span>官网<span lang=EN-US>”</span>）、税安科技或服务商的推广海报页面（以下简称“推广海报”），点</span></b><b><span
      style='letter-spacing:.55pt'>击确认或以其他方式选</span></b><b><span style='letter-spacing:
.5pt'>择接受本协议，包括但不限于未点击确认本协议而事实上使</span></b><b><span
      style='letter-spacing:.55pt'>用了税安科技产品，即</span></b><b><span
      style='letter-spacing:.5pt'>表示您与税安科技已达成协议并同意接受本协议的全部约定内</span></b><b><span
      style='letter-spacing:.55pt'>容。本协议自您通过网</span></b><b><span style='letter-spacing:
.5pt'>络页面点击同意或以其他方式选择接受本协议之日起成立。</span></b><span
      style='letter-spacing:.5pt'>如若双方线下盖章文本与官网或推广海报点击确认或以其他方式选择接</span><span
      style='letter-spacing:.45pt'>受之协议文本不一致的，</span><span style='letter-spacing:
.5pt'>以双方线下盖章文</span><span style='letter-spacing:.45pt'>本为准。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-1.05pt'>1.1.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.5pt'>如果您不同意本协议的任意内容，或者无法准确理解我们对疑问条款的解释，请不要进行后</span><span
      style='letter-spacing:.45pt'>续操作。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-1.05pt'>1.1.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span
      style='letter-spacing:.5pt'>您知悉并确认：本协议由特别提醒、通用条款组成，各组成部分均具有同等法律效</span></b><b><span
      style='letter-spacing:.45pt'>力，与您此前注册交易平台账户时点击确认的《用户注册和服务协议》、</span></b><b><span
      style='letter-spacing:.5pt'>《授权协议</span></b><b><span style='letter-spacing:
.45pt'>》</span></b><b><span style='letter-spacing:.55pt'>构成</span></b><b><span
      style='letter-spacing:.5pt'>一个完整的整体，请您不定期地或在接到变更通知后登录税安科技</span></b><b><span
      style='letter-spacing:.45pt'>网站查看本协议及其组成部分以及其更新后的版本</span></b><span
      style='letter-spacing:.8pt'>。</span></p>

    <p class=MsoBodyText style=''><span
      lang=EN-US>1.2<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-US style='letter-spacing:.35pt'>签约主体</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-1.05pt'>1.2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='letter-spacing:.5pt'>您确认：在您注册、下单、认证或以其他税安科技允许的方式实际使用税安科技产品时，您应当是具有完全民事权利能力和完全民事行为能力的自然人、法人或其他非法人组</span><span
      style='letter-spacing:.45pt'>织（</span><span style='letter-spacing:.5pt'>以下</span><span
      style='letter-spacing:.45pt'>统称为<span lang=EN-US>“</span>法律主体<span lang=EN-US>”</span>）。</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-1.05pt'>1.2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='letter-spacing:.5pt'>如果您的本次签约行为系代理第三方主体而为之，则您承诺您的行为已经获得该第三方充分有效的授权，包括但不限于注册、</span><span
      style='letter-spacing:.6pt'>填写基本信息、上传</span><span style='letter-spacing:.55pt'>证照资料、选择服</span><span
      style='letter-spacing:.5pt'>务类型、</span><span style='letter-spacing:.55pt'>填写确认订单、确认接受本协议、支付产品费用及服务费、</span><span
      style='letter-spacing:.5pt'>申请发票以及进行账户维护及管理等，否则可能因此给第三方造成的损失由您独立</span><span
      style='letter-spacing:.45pt'>承担。</span></p>

    <p class=MsoBodyText style='line-height:
150%'><span lang=EN-US style='color:#333333;letter-spacing:-.6pt'>1.3<span
      style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
      style='letter-spacing:.45pt'>用户信息的填写、核实与变更</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='color:#333333;letter-spacing:-1.05pt'>1.3.1<span
      style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.5pt'>您确认：在交易平台上填写的下单信息、订单信息及提供给税安科技的所有文件和资料都是合法、</span><span
      style='letter-spacing:.55pt'>真实、准确、完整和有效的，不存在虚假记载、重大遗漏或</span><span
      style='letter-spacing:.5pt'>误导性陈述。如因您填写的信息、</span><span style='letter-spacing:
.6pt'>资</span><span
      style='letter-spacing:.55pt'>料不真实、不完整或不准确的，您将承担无法正常使用</span><span
      style='letter-spacing:.5pt'>税安科技产品（包括但不限于不能注册成功、</span><span style='letter-spacing:
.6pt'>用户认证被驳回、账</span><span style='letter-spacing:.55pt'>户被冻结等）或承</span><span
      style='letter-spacing:.5pt'>担您在使用过程中被限制使用所产生的损失，税安科技对此不承担</span><span
      style='letter-spacing:.45pt'>责任。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-1.05pt'>1.3.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.45pt'>您了解并同意：根据《互联网用户账号信息管理规定》及其他相关法律法规的规定，</span><span
      lang=EN-US style='letter-spacing:.55pt'>“</span><span style='letter-spacing:
.55pt'>互联网信息服务提供者为互联网用户提供信息发布、</span><span
      style='letter-spacing:.6pt'>即时通</span><span
      style='letter-spacing:.55pt'>讯等服务的，应当对申请注册相关账号信息的用户进行基于移动电话号码、</span><span
      style='letter-spacing:.6pt'>身份</span><span
      style='letter-spacing:.55pt'>证件号码或者统一社会</span><span
      style='letter-spacing:.6pt'>信用</span><span style='letter-spacing:.55pt'>代码等方式的真实身份信息认证。用户不提供真实身份信息，或者冒用组织机构、</span><span
      style='letter-spacing:.6pt'>他人身份信</span><span style='letter-spacing:.55pt'>息进行虚假注册的，不得为其提供相关服务<span
      lang=EN-US>”</span>。为了更好地为您提</span><span style='letter-spacing:.5pt'>供优质服务，保障您的数据安全，税安科技有权要求您在下单</span><span
      style='letter-spacing:.45pt'>前核实用户信息；同时，</span><span style='letter-spacing:
.6pt'>税安科技</span><span style='letter-spacing:.55pt'>可能会就产品或服务的开通，要求您提供更多的身份资料和信息，做进一步的身份认证或资格验证。如您的</span><span
      style='letter-spacing:.6pt'>认证信</span><span style='letter-spacing:.55pt'>息经核实为虚假信息，税安科技有权清除虚假的认证信息，您需要重新进行认</span><span
      style='letter-spacing:.6pt'>证。</span><span style='letter-spacing:.55pt'>税安科技产品成功开通服务后，超过指定期限未进行用户认证的，您将无法继</span><span
      style='letter-spacing:.6pt'>续使</span><span style='letter-spacing:.55pt'>用，由此产生的责任将由您承担。除工商变更等特殊情况外，认证成功的企业</span><span
      style='letter-spacing:.5pt'>不支持修改或解绑认证</span><span
      style='letter-spacing:.45pt'>信息。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-1.05pt'>1.3.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.5pt'>您应当准确填写并及时更新您提供的联系电话、电子邮件地址</span><span
      style='letter-spacing:.55pt'>等联系方式，以便交易平台及税安科技与您进行有效联系，</span><span
      style='letter-spacing:.5pt'>若发生变更的，您应按</span><span style='letter-spacing:.55pt'>税安科技的有关要求及时更新。如通过这些联系方</span><span
      style='letter-spacing:.5pt'>式无法与您取得联系，导致您在使用税安科技产品过程中产生</span><span
      style='letter-spacing:.4pt'>任何损失或增加费用的，应由您承担。</span></p>

    <p class=MsoBodyText style='line-height:
150%'><span lang=EN-US style='letter-spacing:-.6pt'>1.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-US style='letter-spacing:.35pt'>产品许可</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      style='letter-spacing:.65pt'>您了解并同意：本协议许可的仅是服务期限内的税安科技产品使用权</span><span
      style='letter-spacing:.6pt'>，该许可是附条件的、</span><span style='letter-spacing:.65pt'>非独家的、有期限的、不可转让的，许可使用的税安科技产品著作权</span><span
      style='letter-spacing:.6pt'>归属于税安科技所有，并受《中华人民共和国著作权法》、</span><span
      style='letter-spacing:.7pt'>《计算机软件保护条例》和其他</span><span style='letter-spacing:
.65pt'>有关</span><span style='letter-spacing:.6pt'>法律、</span><span
      style='letter-spacing:.65pt'>法规的保护。使用本协议产品时，您与您的使用人员需要在税安科技</span><span
      style='letter-spacing:.6pt'>平台进</span><span style='letter-spacing:.65pt'>行企业注册并完成用户认证以获得授权。用户认证成功后，您所购买的</span><span
      style='letter-spacing:.6pt'>税安科技产品</span><span style='letter-spacing:.65pt'>使用权将绑定至完成用户认证的企业或个人账户名下，未经税安科技同</span><span
      style='letter-spacing:.6pt'>意，不得私自转</span><span style='letter-spacing:.65pt'>售或转移税安科技产品使用权。税安科技有权对非经税安科技同意而转售</span><span
      style='letter-spacing:.6pt'>或转移税安科技产品</span><span style='letter-spacing:.65pt'>使用权的产品终止使用授权，并税安科技保留追究您的侵权责任和</span><span
      style='letter-spacing:.6pt'>要求您赔偿损失</span><span style='letter-spacing:.5pt'>的权</span><span
      style='letter-spacing:.4pt'>利。</span></p>

    <p class=MsoBodyText style='line-height:
150%'><span lang=EN-US style='letter-spacing:-.6pt'>1.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-US style='letter-spacing:.35pt'>电商店铺</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      style='letter-spacing:.7pt'>您了解并同意</span><span style='letter-spacing:.65pt'>：依据《中华人民共和国电子商务法》的规定<span
      lang=EN-US>“</span>电子商务经营者应当依</span><span style='letter-spacing:.45pt'>法办理市场主体登记<span
      lang=EN-US>”</span>；如您购买涉及电商小程序的产品或模块，例如旺铺、</span><span style='letter-spacing:
.8pt'>微商</span><span style='letter-spacing:.75pt'>城、</span><span
      style='letter-spacing:.65pt'>智慧门店、</span><span
      style='letter-spacing:.7pt'>订货商城等产品，</span><span
      style='letter-spacing:.65pt'>在使用店铺小程序前需要完成企业实名认证，同时您需</span><span
      style='letter-spacing:.7pt'>要确保提供</span><span style='letter-spacing:.65pt'>《商家隐私政策》以符合监管要求；与此同时，根据工业和信息化部发布</span><span
      style='letter-spacing:.7pt'>的《工业和</span><span style='letter-spacing:.65pt'>信息化部关于开展移动互联网应用程序备案工作的通知》，您需要履行所</span><span
      style='letter-spacing:.5pt'>属小程序的备案手续，未履行备案手续所造成的相关后果和责任均由</span><span
      style='letter-spacing:.45pt'>您承担。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span lang=EN-US
                                                        style='letter-spacing:.45pt'>&nbsp;</span>
    </p>

    <p class=MsoBodyText style='line-height:
150%'><b><span lang=EN-US style='letter-spacing:-1.1pt'>2<span
      style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
      lang=EN-US style='letter-spacing:.2pt'>服务</span></b></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.6pt'>2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='letter-spacing:.45pt'>服务内容：本协议中<span lang=EN-US>“</span>服务<span
      lang=EN-US>”</span>指税安科技向您提供的交易平台上的产品服务，以及税安科技</span><span style='letter-spacing:
.6pt'>或税安科技的区域服务商为您提供的产品培训、</span><span
      style='letter-spacing:.7pt'>实施服务和使用答</span><span
      style='letter-spacing:.65pt'>疑。</span></p>

    <p class=MsoBodyText style='line-height:
150%'><span lang=EN-US style='letter-spacing:-.6pt'>2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-US style='letter-spacing:.4pt'>提供服务的主体及内容</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:.5pt'>2.2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='letter-spacing:.5pt'>为了保障您能享受到全面的税安科技产品及咨询服务，在您购买并激活产品或服务后，由您的服务商为您提供产品培训、产品使用问题解答等服务，您可以在交易订单中查阅服务商信息。如您的服务商后续与税安科技终止合作或不具备相应授权服务资质时，税安科技将会为您提供新的服务商，以确保服务的连续性和完整性。如您没有指定的服务商，将由税安科技为您服务。</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:.5pt'>2.2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='letter-spacing:.5pt'>税安科技或您的服务商会在您使用的产品或服务不能满足您的需求时，为您推荐更优质的税安科技产品或服务，同时为您提供下单、使用、续费和增购等过程中的培训和答疑等在线服务。如您所购买的税安科技产品需要上门指导、现场实施维护等实施服务，由此产生的实施费用由税安科技或服务商收取，《实施服务协议》视具体情况由您与税安科技或服务商自主签署，并最终由服务商完成相关实施工作。</span>
    </p>

    <p class=MsoBodyText style='line-height:
150%'><b><span lang=EN-US style='letter-spacing:-1.05pt'>3<span
      style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
      lang=EN-US style='letter-spacing:.4pt'>服务费用及发票</span></b></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.6pt'>3.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.45pt'>产品及服务费用将在交易平台予以列明公示，您可自行选择具体类型并按列明的价格予</span><span
      style='letter-spacing:.5pt'>以支</span><span style='letter-spacing:.4pt'>付。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:.45pt'>3.2<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span
      style='letter-spacing:.45pt'>如您购买上门指导、现场实施维护等实施服务，费用标准以《实施服务协议》约定为准。</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.6pt'>3.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='letter-spacing:.45pt'>我们仅支持预付费模式，在您成功付费后，我们将开始为您提供服务。若您下单后未在交易平台规定的时间（<span
      lang=EN-US>48</span></span><span lang=EN-US style='letter-spacing:1.1pt'> </span><span
      style='letter-spacing:.45pt'>小时）内付款，所生成的订单将自动失效。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.6pt'>3.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.45pt'>服务期满双方愿意继续合作的，建议您在服务期限届满前 </span><span
      lang=EN-US style='letter-spacing:.8pt'>30</span><span lang=EN-US
                                                            style='letter-spacing:.5pt'> </span><span
      style='letter-spacing:.45pt'>日内续费，以使服务得以</span><span
      style='letter-spacing:.65pt'>继续进行，服务期满后如未及时续费的，本协议项下服务自</span><span
      style='letter-spacing:.6pt'>动终止。如续费时税安科技对产品体系、</span><span style='letter-spacing:
.65pt'>名称或价格有调整，您愿意继续使用相应服务的，应当按照</span><span style='letter-spacing:.6pt'>税安科技届时有效的收</span><span
      style='letter-spacing:.5pt'>费政</span><span style='letter-spacing:.45pt'>策付费。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:.45pt'>3.5<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='letter-spacing:.45pt'>您完全理解税安科技价格体系中所有的赠送服务项目或活动均为税安科技在正常服务价格之外的特别优惠，优惠内容不包括赠送服务项目的修改、更新及维护费用，并且赠送的服务项目不可折价、冲抵服务价格。</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><a
      name="OLE_LINK2"><span lang=EN-US style='letter-spacing:.45pt'>3.6<span
      style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      style='letter-spacing:.45pt'>关于退货和换货：</span></a><a name="OLE_LINK6"><span
      style='letter-spacing:.45pt'>税安科技所有商品不提供退换货服务，该规则已在订购流程中告知。</span></a>
    </p>

    <p class=MsoBodyText style='line-height:
150%'><b><span lang=EN-US style='letter-spacing:-1.05pt'>4<span
      style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
      lang=EN-US style='letter-spacing:.4pt'>双方的权利与义务</span></b></p>

    <p class=MsoBodyText style='line-height:
150%'><span lang=EN-US style='letter-spacing:-.6pt'>4.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='letter-spacing:.4pt'>税安科技的权利与义务：</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:.5pt'>4.1.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.5pt'>税安科技有权按照本协议的约定向您收取产品及服务费用，并在收到您支付的相关费用后提供相应的产品及服务。</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:.5pt'>4.1.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='letter-spacing:.5pt'>为改善用户体验，完善服务内容，税安科技有权定期或不定期对产品、服务、平台、系统、软件等进行升级、维护优化等日程巡检及维护，在不影响您正常使用的情况下可能导致您所使用的服务短时间的中断或暂停。</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:.5pt'>4.1.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='letter-spacing:.5pt'>若您的行为违反国家法律法规或者税安科技用户服务协议或有关规则，税安科技有权暂时或永久限制您账户下所使用的所有产品及<span
      lang=EN-US>/</span>或服务的部分或全部功能。</span></p>

    <p class=MsoBodyText style='line-height:
150%'><span lang=EN-US style='letter-spacing:-.6pt'>4.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-US style='letter-spacing:.4pt'>您的权利与义务：</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:.5pt'>4.2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.5pt'>您有权在本协议约定的范围内使用税安科技产品及服务并及时支付费用。</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:.5pt'>4.2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='letter-spacing:.5pt'>您有权在因税安科技原因造成产品连续<span
      lang=EN-US> 72 </span>小时不能正常使用的情况下，要求终止本协议，但非因税安科技原因引起的使用异常情况除外。</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:.5pt'>4.2.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='letter-spacing:.5pt'>您在购买前有义务在交易平台上对产品内容进行了解，并在此确认：您是在充分了解税安科技产品的相关功能以及其所能满足的需求的情形下，做出的购买决定，产品一经开通，即会产生相应的服务费用。</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><b><span
      lang=EN-US style='letter-spacing:.5pt'>4.2.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span style='letter-spacing:.5pt'>您有义务妥善保管税安科技相关的账号、口令、密码以及与此绑定的手机号码、手机验证码等有关信息，因您维护或保管不当致使上述账号、口令、密码等丢失或泄漏所引起的一切损失和后果均由您承担。</span></b>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:.5pt'>4.2.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='letter-spacing:.5pt'>若您发现他人冒用或盗用了您交易平台或者税安科技产品有关的账号、口令、密码等，您应立即以有效书面方式通知税安科技暂停相关交易和服务，同时您理解税安科技对您的请求采取行动需要合理的期限，在此之前，税安科技对已执行的指令及所导致的您的损失不承担任何责任。</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:.5pt'>4.2.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='letter-spacing:.5pt'>您了解税安科技无法保证其所提供的服务毫无瑕疵，但税安科技承诺不断提升服务质量及服务水平。您同意：即使税安科技提供的服务存在瑕疵，但上述瑕疵是当时行业技术水平所无法避免的，其将不被视为税安科技违约。</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:.5pt'>4.2.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='letter-spacing:.5pt'>您需要根据国家有关法律、行政法规、行政规章以及监管指令，认真执行网络安全、数据安全与隐私保护的相关管理规定，并不会利用税安科技提供的产品和服务进行存储、发布、传播如下信息和内容：</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-1.3pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='letter-spacing:.35pt'>违反国家法律法规政策的任何内容和<span
      lang=EN-US>/</span>或信息；</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-1.3pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='letter-spacing:.3pt'>违反国家规定的政治宣传和<span lang=EN-US>/</span>或新闻信息；</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-1.3pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='letter-spacing:.25pt'>涉及国家秘密和<span lang=EN-US>/</span>或安全的信息；</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-1.3pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='letter-spacing:.35pt'>封建迷信和<span lang=EN-US>/</span>或淫秽、色情信息、教唆犯罪的信息；</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-1.3pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='letter-spacing:.4pt'>博彩、赌博信息；</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-1.3pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='letter-spacing:.45pt'>违反国家民族和宗教政策的信息；</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-1.3pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='letter-spacing:.45pt'>防碍互联网运行安全的信息；</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-1.3pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='letter-spacing:.35pt'>侵害他人合法权益的信息和<span lang=EN-US>/</span>或其他有损于公序良俗的信息或内容；</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-1.3pt'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='letter-spacing:.45pt'>在产品内上传或使用未获得完全知识产权、所有权或者第三方合法授权的任何内容。您同时承诺不使用税安科技的产品、</span><span
      style='letter-spacing:.55pt'>服务为他人发布上述</span><span style='letter-spacing:.5pt'>不符合国家规定和<span
      lang=EN-US>/</span>或本协议条款约定</span><span
      style='letter-spacing:.45pt'>的信息内容提供任何便利。如您违反</span><span
      style='letter-spacing:.25pt'> </span><span lang=EN-US style='letter-spacing:
.4pt'>4.2.7</span><span lang=EN-US style='letter-spacing:.25pt'> </span><span
      style='letter-spacing:.5pt'>条项下内容，由此引起的一切法律责任与损失</span><span style='letter-spacing:
.65pt'>均由您承担，同时税安科技有权终止对您的服务，如因此给税安科技</span><span
      style='letter-spacing:.6pt'>造成损失的，您承诺将承</span><span
      style='letter-spacing:.5pt'>担全部赔</span><span style='letter-spacing:.45pt'>偿责任。</span>
    </p>

    <p class=MsoNormal style='line-height:150%'><span lang=EN-US>&nbsp;</span></p>

    <p class=MsoBodyText style='line-height:150%'><b><span
      lang=EN-US style='letter-spacing:.5pt'>4.2.8<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span style='letter-spacing:.5pt'>如果购买<span lang=EN-US>-</span>税安科技私有化部署产品、网络系统、应用程序的，需由您进行管理和运营，同时您理解并同意如下：</span></b>
    </p>

    <p class=MsoBodyText style='line-height:150%'><b><span
      lang=EN-US style='letter-spacing:-.85pt'>4.2.8.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span style='letter-spacing:.55pt'>您需要根据自身所处理数据和业务系统的安全要求选择、配置和使用必要的安全防护设备及措施。您知悉并确认，使用安全服务是提升计算机信息系统、</span></b><b><span
      style='letter-spacing:.8pt'>设备</span></b><b><span
      style='letter-spacing:.75pt'>安全性的必要</span></b><b><span
      style='letter-spacing:.6pt'>措施，您可根据业务需要自行购买税安科技提供的安全产品及服务，<a
      name="OLE_LINK3">税安科技</a>将按</span></b><b><span
      style='letter-spacing:.55pt'>照本协议中</span></b><b><span style='letter-spacing:
.6pt'>您所购买的安全产品的相关功能向您提供安全保护服务，但您仍需要结合产品</span></b><b><span style='letter-spacing:
.55pt'>的使用情况</span></b><b><span
      style='letter-spacing:.6pt'>自行判断安全保护服务是否符合自身数据和业务系统安全性和合规性要求。由</span></b><b><span
      style='letter-spacing:.55pt'>于未进行合</span></b><b><span style='letter-spacing:
.5pt'>理的安全配置导</span></b><b><span
      style='letter-spacing:.45pt'>致的网络及数据安全风险由您负责。</span></b></p>

    <p class=MsoBodyText style='line-height:150%'><b><span
      lang=EN-US style='letter-spacing:-.85pt'>4.2.8.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span
      style='letter-spacing:.4pt'>您需要根据国家有关法律、行政法规、行政规章以及监管指令，认真执行网络安全、</span></b><b><span
      style='letter-spacing:.65pt'>数据安全与隐私</span></b><b><span style='letter-spacing:
.6pt'>保护的相关管理规定；您有义务采取适当的安全措施，以保护该等产品</span></b><b><span style='letter-spacing:
.45pt'>运行环境所在计算机<span lang=EN-US>/</span>服务器、</span></b><b><span
      style='letter-spacing:.5pt'>网络、数据存储的安全。因此您在</span></b><b><span
      style='letter-spacing:.45pt'>使用该等产品时，需要</span></b><b><span style='letter-spacing:
.6pt'>采用及时更新产品补丁、</span></b><b><span style='letter-spacing:.65pt'>数据异地备份、</span></b><b><span
      style='letter-spacing:.6pt'>设置强密码、安装杀毒软件和防火墙等必要的安</span></b><b>全措<span
      style='letter-spacing:.55pt'> </span>施<span
      style='letter-spacing:.9pt'>。如因您未采取上</span><span
      style='letter-spacing:.85pt'>述措施，导致遭受</span><span
      style='letter-spacing:.45pt'>网络攻击、</span><span
      style='letter-spacing:.5pt'>黑客入侵、中病毒等</span><span style='letter-spacing:.45pt'>事件以及产生的任何损失由您承担。</span></b>
    </p>

    <p class=MsoBodyText style='line-height:150%'><b><span
      lang=EN-US style='letter-spacing:.5pt'>4.2.9<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='letter-spacing:.5pt'>SaaS </span></b><b><span
      style='letter-spacing:.5pt'>产品及服务的使用您需要查看其相应配置文档，以了解可自定义的安全配置，并做出符合自身数据和业务系统安全性和合规性要求的配置选择。由于未进行合理的安全配置导致的网络及数据安全风险由您负责。</span></b>
    </p>

    <p class=MsoBodyText style='line-height:150%'><b><span
      lang=EN-US style='letter-spacing:.5pt'>4.2.10<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></b><b><span style='letter-spacing:.5pt'>您有义务承诺遵守《中华人民共和国网络安全法》、《中华人民共和国著作权法》等相关法律法规的规定，不支持、不参与、不使用盗版产品，包括但不限于未经授权安装或使用产品、破解或修改产品、共享或传播盗版产品、提供盗版产品销售渠道或服务等。盗版产品不享受正版产品的更新和维护服务，可能存在漏洞和病毒等安全风险，数据安全将无法得到保障。税安科技有权不为盗版产品提供服务，并保留追究侵权者的法律责任。</span></b>
    </p>

    <p class=MsoBodyText style='line-height:
150%'><b><span lang=EN-US style='letter-spacing:-1.05pt'>5<span
      style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
      lang=EN-US style='letter-spacing:.35pt'>业务数据</span></b></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.6pt'>5.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.45pt'>税安科技理解并认可，您通过税安科技提供的服务，加工、存储、上传、下载、分发以及通</span><span
      style='letter-spacing:.5pt'>过其他方式处理的数据，均为您的用户业务数据，您完全拥有您的用户业</span><span
      style='letter-spacing:.45pt'>务数据。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.6pt'>5.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><b><span
      style='letter-spacing:.45pt'>对于您的用户业务数据，税安科技除执行您的服务要求外，不进行任何未获授权的使用及</span></b><b><span
      style='letter-spacing:.5pt'>披露</span></b><b><span
      style='letter-spacing:.45pt'>，但以下情形除外：</span></b></p>

    <p class=MsoBodyText style='line-height:150%'><b><span
      lang=EN-US style='letter-spacing:.5pt'>5.2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span style='letter-spacing:.5pt'>在获取同意的情况下转让：税安科技在获得您的同意后，向其他方（包括但不限于税安科技的合作伙伴、征信公司等）转让您的用户与业务数据；</span></b>
    </p>

    <p class=MsoBodyText style='line-height:150%'><b><span
      lang=EN-US style='letter-spacing:.5pt'>5.2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span style='letter-spacing:.5pt'>您和税安科技另行协商一致；</span></b></p>

    <p class=MsoBodyText style='line-height:150%'><b><span
      lang=EN-US style='letter-spacing:.5pt'>5.2.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span
      style='letter-spacing:.5pt'>税安科技按照相关法律法规或政策要求提供。</span></b></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.6pt'>5.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.45pt'>您可自行对您的用户业务数据进行修改、删除等操作。如您自行修改或删除数据的，系</span><span
      style='letter-spacing:.6pt'>统将自动按照您的指令修改或删除您的数据。数据的修改、</span><span
      style='letter-spacing:.75pt'>删除等操作可能</span><span
      style='letter-spacing:.65pt'>导致数据变更</span><span
      style='letter-spacing:.5pt'>或无法恢复，您应谨慎</span><span
      style='letter-spacing:.45pt'>操作。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.6pt'>5.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.5pt'>如服务期限届满且您不再续费致使服务终止的（含由于您违反本协议致使服务提前终止</span><span
      style='letter-spacing:.6pt'>等的情形）</span><span
      style='letter-spacing:.65pt'>，税安科技建议您提前</span><span
      style='letter-spacing:.35pt'> </span><span lang=EN-US style='letter-spacing:
.4pt'>15</span><span lang=EN-US style='letter-spacing:.35pt'> </span><span
      style='letter-spacing:.65pt'>个自然日将您在使用税安科技产品及服务所形成的相</span><span
      style='letter-spacing:.7pt'>关数据迁移到本地。前述期限届满后，您未</span><span style='letter-spacing:
.6pt'>将数据迁移到本地的，即视为您不需要上述数</span><span style='letter-spacing:.7pt'>据，税安科技将有权删除所有您在使用税安科技</span><span
      style='letter-spacing:.6pt'>产品及服务过程中所形成的相关数据，包括</span><span style='letter-spacing:
.5pt'>但不限于所有系统缓存或者备份的副本，不再保留您的</span><span style='letter-spacing:.45pt'>任何数据资料，且不承担任何责任。</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.6pt'>5.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='letter-spacing:.2pt'>数据一经删除，即不可恢复，您应承担因上述 </span><span
      lang=EN-US style='letter-spacing:.25pt'>5.3</span><span lang=EN-US
                                                              style='letter-spacing:.2pt'> </span><span
      style='letter-spacing:.3pt'>和</span><span
      style='letter-spacing:.2pt'> </span><span lang=EN-US style='letter-spacing:
.1pt'>5.4</span><span lang=EN-US style='letter-spacing:.2pt'> </span><span
      style='letter-spacing:.2pt'>条款数据删除所引发的所有后</span><span style='letter-spacing:
.45pt'>果和责任。您理解并同意，税安科技没有继续保留、</span><span
      style='letter-spacing:.6pt'>导出</span><span
      style='letter-spacing:.55pt'>或者返还用户业务数据的义务。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.6pt'>5.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><b><span
      style='letter-spacing:.45pt'>您理解并认可，根据适用的法律，若税安科技共享、转让经匿名化处理的和采取其他必要</span></b><b><span
      style='letter-spacing:.6pt'>措施进行处理的业务信息，确保数据接收方无法重新识别特定用户且不能复原</span></b><b><span
      style='letter-spacing:.55pt'>（包括并不</span></b><b><span style='letter-spacing:
.45pt'>限于模块功能的使用频率、</span></b><b><span style='letter-spacing:.5pt'>财务<span
      lang=EN-US>/</span></span></b><b><span
      style='letter-spacing:.45pt'>业务等经营指标，以及公开的图像、文字、视频、音乐、</span></b><b><span
      style='letter-spacing:.55pt'>附加程序、</span></b><b><span style='letter-spacing:
.65pt'>店铺装修等信息），或税安科技可能会对</span></b><b><span style='letter-spacing:.55pt'>收集的业务信息进行匿名化地研究、分</span></b><b><span
      style='letter-spacing:.5pt'>析和预</span></b><b><span style='letter-spacing:.45pt'>测，用于改善税安科技产品的内容和布局，为您的商业决策提供产品及<span
      lang=EN-US>/</span>或服务支撑，以及改进税安科技的产品及<span lang=EN-US>/</span>或服务（包括使用匿名数据进行机器学习或模型算法训练、</span></b><b><span
      style='letter-spacing:.5pt'>模拟</span></b><b><span
      style='letter-spacing:.55pt'>重现产品问题并处理）</span></b><b><span
      style='letter-spacing:.65pt'>，使用在税安科技</span></b><b><span style='letter-spacing:
.6pt'>产品增值或免费的数据、内容等功能服务中，不属</span></b><b><span style='letter-spacing:.55pt'>于个人信息的对外委托处理、</span></b><b><span
      style='letter-spacing:.65pt'>共享、转让以及公</span></b><b><span style='letter-spacing:
.6pt'>开披露行为，无需另行再次向您通知并征</span></b><b><span
      style='letter-spacing:.5pt'>得您的</span></b><b><span
      style='letter-spacing:.4pt'>同意。</span></b></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.6pt'>5.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.45pt'>您应对您的用户业务数据的来源及内容负责，税安科技提示您谨慎判断数据来源及内容的</span><span
      style='letter-spacing:.6pt'>合法性。您将承担因您的用户业务数据内容违反法律法规、</span><span
      style='letter-spacing:.75pt'>部门规章或国家</span><span
      style='letter-spacing:.65pt'>政策而造成的</span><span
      style='letter-spacing:.5pt'>相应结果</span><span style='letter-spacing:.45pt'>及责任。</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      style='letter-spacing:.45pt'>如您基于自身业务目的需要选择在中华人民共和国境外的数据中心储存并处理业务数据，</span><span
      style='letter-spacing:.5pt'>可能会导致您的用户业务数据出境。您作为</span><span style='letter-spacing:
.4pt'>用户业务数据的完全控制方应履行相应的数据</span><span style='letter-spacing:.5pt'>出境合规义务，包括但不限于完成数据出境申报，与境外数据接收方签署</span><span
      style='letter-spacing:.45pt'>协议等。</span></p>

    <p class=MsoBodyText style='line-height:
150%'><span lang=EN-US style='letter-spacing:-1.05pt'>6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='letter-spacing:.35pt'>知识产权</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.6pt'>6.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.45pt'>一方向另一方提供的任何资料、技术或技术支持、软件、服务等的知识产权均属于提供</span><span
      style='letter-spacing:.6pt'>一方或相应权利人所有；除提供方或相应权利人明示同意外，另一方无权复制、</span><span
      style='letter-spacing:.95pt'>传播</span><span style='letter-spacing:.9pt'>、转</span><span
      style='letter-spacing:.45pt'>让、</span><span
      style='letter-spacing:.55pt'>许可或提供他人使用上述知识</span><span
      style='letter-spacing:.45pt'>成果，否则应承担相应的责任。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.6pt'>6.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.45pt'>您应保证提交给税安科技的材料、对税安科技服务的使用及使用税安科技服务所产生的成果未</span><span
      style='letter-spacing:.6pt'>侵犯任何第三方的合法权益；否则因此引起的索赔、</span><span
      style='letter-spacing:.7pt'>诉讼等，您应负责解决</span><span style='letter-spacing:.65pt'>，并承担相应</span><span
      style='letter-spacing:.5pt'>损失</span><span style='letter-spacing:.45pt'>和费用。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.6pt'>6.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.45pt'>我们尊重知识产权，除非获得了您的同意或本协议另有约定，税安科技不会擅自使用、修改、</span><span
      style='letter-spacing:.55pt'>复制、改变或公开发表</span><span style='letter-spacing:.45pt'>您拥有知识产权的成果。</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.6pt'>6.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='letter-spacing:.45pt'>除非另行说明，税安科技网站</span><span
      style='letter-spacing:.95pt'> </span><span lang=EN-US style='letter-spacing:
.4pt'>Logo</span><span style='letter-spacing:.4pt'>、<span lang=EN-US>“</span>税安科技<span
      lang=EN-US>”</span>等文字、图形及其组合，</span><span style='letter-spacing:.65pt'>以及税安科技网站的其他标识等的知识产权为税安科技</span><span
      style='letter-spacing:.6pt'>所有。<span lang=EN-US>“</span>知识产权<span lang=EN-US>”</span>包括在专利法、</span><span
      style='letter-spacing:.65pt'>著作权法、商标法、反不正当竞争法等法律中规定的所有权</span><span
      style='letter-spacing:.6pt'>利和利益，以及其</span><span
      style='letter-spacing:.45pt'>中的所有应用、</span><span
      style='letter-spacing:.55pt'>更新、</span><span style='letter-spacing:.5pt'>扩展和恢复。</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.6pt'>6.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.45pt'>您不得攻击税安科技官网，修改、改编、翻译云服务所使用的软件、技术、材料等，不得</span><span
      style='letter-spacing:.6pt'>通过反向工程、</span><span style='letter-spacing:.65pt'>反编译、反汇编或其他类似行为获得云服务涉及的源代码，</span><span
      style='letter-spacing:.6pt'>否则由此引起</span><span style='letter-spacing:.5pt'>的一切法律后果由您负责，税安科技有权依法追究您的法</span><span
      style='letter-spacing:.45pt'>律责任。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.6pt'>6.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.6pt'>您应尊重税安科技和第三方的知识产权和其他合法权利<span
      lang=EN-US>/</span>权益，并保证在发生侵犯前述权</span><span style='letter-spacing:.65pt'>益的违法事件时，保护税安科技及其合作伙伴等免于因该等事</span><span
      style='letter-spacing:.6pt'>件受到影响或损失，税安科技保</span><span style='letter-spacing:
.65pt'>留在您侵犯税安科技及<span lang=EN-US>/</span>或其他第三</span><span style='letter-spacing:
.6pt'>方的合法权利<span lang=EN-US>/</span>权益时终止向您提供服务并不退还任何款</span><span
      style='letter-spacing:.5pt'>项的</span><span style='letter-spacing:.45pt'>权利。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.6pt'>6.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='letter-spacing:.6pt'>如果第三方机构或个人对您使用税安科技产品及<span
      lang=EN-US>/</span>或服务所涉及的相关素材的知识产权归</span><span
      style='letter-spacing:.65pt'>属提出质疑或投诉，或对您使用的税安科技产品或服务的知识</span><span
      style='letter-spacing:.6pt'>产权的归属提出质疑或投诉，</span><span style='letter-spacing:
.65pt'>您和税安科技均有责任出具相关知识产权证明材料，并配合对</span><span
      style='letter-spacing:.6pt'>方的相关投诉处理工作。对于因此引起的索赔、</span><span
      style='letter-spacing:.65pt'>诉讼，违约方应负责解决，并承担由此产生的费用和损失，</span><span
      style='letter-spacing:.6pt'>以及使另一</span><span
      style='letter-spacing:.5pt'>方免</span><span
      style='letter-spacing:.4pt'>责。</span></p>

    <p class=MsoBodyText style='line-height:
150%'><b><span lang=EN-US style='letter-spacing:-1.1pt'>7<span
      style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
      lang=EN-US style='letter-spacing:.3pt'>保密条款</span></b></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.6pt'>7.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='letter-spacing:.45pt'>本协议项下<span lang=EN-US>“</span>保密信息<span
      lang=EN-US>”</span>是指一方（以下简称<span lang=EN-US>“</span>接受方<span lang=EN-US>”</span>）从另一方（以下简称<span
      lang=EN-US>“</span>披露方<span lang=EN-US>”</span>）</span><span style='letter-spacing:
.5pt'>取得的、获知的、或因双方履行本协议而产生的商业秘密、技术秘密和<span
      lang=EN-US>/</span>或其他</span><span
      style='letter-spacing:.45pt'>应予</span><span style='letter-spacing:.65pt'>保密的信息和资料（包括但不限于产品计划，价格，财务及</span><span
      style='letter-spacing:.6pt'>营销规划，业务战略，客户信</span><span style='letter-spacing:
.55pt'>息和数据，研发资料，软硬件，<span lang=EN-US>API</span></span><span lang=EN-US
                                                                        style='letter-spacing:1.55pt'> </span><span
      style='letter-spacing:.6pt'>应用数据接口，技术说明，设计，特殊公式，特殊算</span><span
      style='letter-spacing:.4pt'>法等）</span><span style='letter-spacing:.55pt'>。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.6pt'>7.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.45pt'>接受方同意对披露方之上述保密信息予以保密，并严格限制接触上述保密信息的员工遵</span><span
      style='letter-spacing:.5pt'>守本条之保密义务，但不适用于以下</span><span style='letter-spacing:
.45pt'>情况：</span></p>

    <p class=MsoBodyText style='line-height:
150%'><span lang=EN-US style='font-family:"Times New Roman",serif;letter-spacing:
-.1pt'>(1)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
      style='letter-spacing:.45pt'>在签署本协议之时或之前，接受方已合法知悉且可不受限制地使用该等保密信息；</span>
    </p>

    <p class=MsoBodyText style='line-height:
150%'><span lang=EN-US style='font-family:"Times New Roman",serif;letter-spacing:
-.1pt'>(2)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
      style='letter-spacing:.45pt'>非因接受方违反本协议约定而被合法公开的信息；</span></p>

    <p class=MsoBodyText style='line-height:
150%'><span lang=EN-US style='font-family:"Times New Roman",serif;letter-spacing:
-.1pt'>(3)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
      style='letter-spacing:.45pt'>保密信息是接受方从没有保密义务的第三方获得的；</span></p>

    <p class=MsoBodyText style='line-height:
150%'><span lang=EN-US style='font-family:"Times New Roman",serif;letter-spacing:
-.1pt'>(4)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
      style='letter-spacing:.45pt'>保密信息是由接受方独立开发取得的信息；</span></p>

    <p class=MsoBodyText style='line-height:
150%'><span lang=EN-US style='font-family:"Times New Roman",serif;letter-spacing:
-.1pt'>(5)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
      style='letter-spacing:.6pt'>接受方应法院、证券交易所或其他法律、行政管理部门要求（通过口头提问、询问、</span><span
      style='letter-spacing:.45pt'>要求资料或文件、</span><span
      style='letter-spacing:.55pt'>传唤、民事</span><span
      style='letter-spacing:.5pt'>或刑事调查或其他程序）因而透露保密信息；</span></p>

    <p class=MsoBodyText style='line-height:
150%'><span lang=EN-US style='letter-spacing:.15pt'>(6)</span><span lang=EN-US
                                                                    style='letter-spacing:.6pt'> </span><span
      style='letter-spacing:.6pt'>接受方为向行政管理部门、行业协会、银行等机构申请某项业务资质、获得某项认定、或符合国</span><span
      style='letter-spacing:.5pt'>家、</span><span style='letter-spacing:.55pt'>行业</span><span
      style='letter-spacing:.5pt'>标准<span lang=EN-US>/</span>认证，需结合对方情况向前述机构提交材料或进行说明的而披</span><span
      style='letter-spacing:.7pt'>露</span><span style='letter-spacing:.65pt'>的信息，在该等情况下，接受方应秉持必要情况下最少披露原则及要求因此获知保</span><span
      style='letter-spacing:.5pt'>密信息的机构按不低于本协议的标准予以</span><span style='letter-spacing:
.45pt'>保密。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.6pt'>7.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.45pt'>本协议双方明确认可保密信息是双方的重要资产，双方同意尽最大的努力保护上述保密</span><span
      style='letter-spacing:.65pt'>信息不被披露。一旦发现有上述保密信息泄露事件，双方应</span><span
      style='letter-spacing:.6pt'>合作采取一切合理措施避免或</span><span style='letter-spacing:
.5pt'>者减轻损害后果的</span><span style='letter-spacing:.45pt'>产生。</span></p>

    <p class=MsoBodyText style='line-height:
150%'><b><span lang=EN-US style='letter-spacing:-1.05pt'>8<span
      style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
      style='letter-spacing:.4pt'>服务期限与变更、中断、终止</span></b></p>

    <p class=MsoBodyText style='line-height:
150%'><span lang=EN-US style='letter-spacing:-.6pt'>8.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.45pt'>服务期限以订单支付日期为起始日，若该订单无需支付，则以订单提交日期为起始日。</span>
    </p>

    <p class=MsoBodyText style='line-height:
150%'><span lang=EN-US style='letter-spacing:-.6pt'>8.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='letter-spacing:.45pt'>发生下列情形，服务期限提前终止：</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:.5pt'>8.2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='letter-spacing:.5pt'>双方协商一致提前终止的；</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:.5pt'>8.2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='letter-spacing:.5pt'>您严重违反本协议（包括但不限于：<span
      lang=EN-US>a.</span>您未按照协议约定履行付款义务，及<span lang=EN-US>/</span>或<span
      lang=EN-US>
b.</span>您严重违反相关法律法规规定或您严重违反本协议项下之任一承诺内容等），税安科技有权提前终止服务，并不退还您已经支付的费用；</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><b><span
      lang=EN-US style='letter-spacing:.5pt'>8.2.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span style='letter-spacing:.5pt'>您理解并充分认可，虽然税安科技已经建立（并将根据技术的发展不断完善）必要的技术措施来防御包括计算机病毒、网络入侵和攻击破坏（包括但不限于<span
      lang=EN-US> DDOS</span>）等危害网络安全的事项或行为（以下统称<span
      lang=EN-US>“</span>该等行为<span
      lang=EN-US>”</span>），但鉴于网络安全技术的局限性、相对性以及该等行为的不可预见性，因此如因您遭遇该等行为而给税安科技或者税安科技的其他的网络或服务器（包括但不限于本地及外地和国际的网络、服务器等）带来危害，或影响税安科技与国际互联网或者税安科技与特定网络、服务器及税安科技内部的通畅联系，税安科技可决定暂停或终止服务，如果终止服务的，<a
      name="OLE_LINK4">将按照实际提供服务月份计算（不足一个月的按天计）服务费用</a>，将剩余款项退回，除此以外税安科技不承担其他责任。</span></b>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:.5pt'>8.2.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='letter-spacing:.5pt'>服务因税安科技无法控制的事由而无法提供（包括但不限于不可抗力），或继续提供服务将导致您或税安科技违反适用的法律、法规或规章。</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.6pt'>8.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><b><span
      style='letter-spacing:.45pt'>税安科技可不时修改、终止服务，在我们终止提供您正在使用的任何服务或任何服务功能</span></b><b><span
      style='letter-spacing:.55pt'>之前，我们将提前</span></b><b><span style='letter-spacing:
.9pt'> </span></b><b><span lang=EN-US style='letter-spacing:.45pt'>30</span></b><b><span
      lang=EN-US style='letter-spacing:.95pt'> </span></b><b><span style='letter-spacing:
.55pt'>日在税安科技官网上通告或给您发网站内通知或其他书面通知的方式</span></b><b><span style='letter-spacing:
.45pt'>通知您，届时税安科技应将您已预付但未消费的款项退还给您。您知悉</span></b><b><span style='letter-spacing:
.6pt'>并确认，部分产品服务或产品服务中的部分功能的终止并不影响您在使用其他</span></b><b><span style='letter-spacing:
.55pt'>产品服务时</span></b><b><span style='letter-spacing:.5pt'>依</span></b><b><span
      style='letter-spacing:.45pt'>据对本协议的遵守。</span></b></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.6pt'>8.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.45pt'>在法律允许的范围内，如发生不可抗力时，税安科技有权不经通知而中断、中止或终止服</span><span
      style='letter-spacing:.65pt'>务，服务变更或中断或终止后税安科技会尽最大努力及时恢复</span><span
      style='letter-spacing:.6pt'>服务或通过一种或多种方式发</span><span style='letter-spacing:
.65pt'>出通知，或受您的信息因素或其他未知因素影响不能保证您</span><span style='letter-spacing:.6pt'>一定获得到通知，遇到故障请</span><span
      style='letter-spacing:.5pt'>您及时致电税安科技在线客服或客服电</span><span style='letter-spacing:
.45pt'>话反馈。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.6pt'>8.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='letter-spacing:.3pt'>税安科技存在免费及<span lang=EN-US>/</span>或付费产品及<span
      lang=EN-US>/</span>或服务。若您选择了需要付费的产品及<span
      lang=EN-US>/</span>或服务，税安科技</span><span
      style='letter-spacing:.5pt'>根据您自主选择的产品及<span lang=EN-US>/</span>或服务内容向您收取相应的费用。若您未按时足额</span><span
      style='letter-spacing:.45pt'>付费，税安科技有权中断、</span><span style='letter-spacing:
.55pt'>中止</span><span style='letter-spacing:.5pt'>提供服务，并有权提前终止本协议。</span></p>

    <p class=MsoBodyText style='line-height:
150%'><b><span lang=EN-US style='letter-spacing:-.6pt'>9<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='letter-spacing:.4pt'>责任范围及责任限制</span></b></p>

    <p class=MsoBodyText style='line-height:
150%'><span lang=EN-US style='letter-spacing:-.4pt'>9.1<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='letter-spacing:.45pt'>本协议任何一方违约均须依法承担违约责任。</span></p>

    <p class=MsoBodyText style='line-height:
150%'><span lang=EN-US style='letter-spacing:-.4pt'>9.2<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span
      style='letter-spacing:.45pt'>您理解，鉴于计算机、互联网的特殊性，下述情况不属于税安科技违约：</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:.5pt'>9.2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.5pt'>税安科技在进行服务器配置、维护时，需要短时间中断服务；</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:.5pt'>9.2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='letter-spacing:.5pt'>由于<span lang=EN-US> Internet </span>上的通路阻塞造成您网站访问速度下降；</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:.5pt'>9.2.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='letter-spacing:.5pt'>由于有关国家机关下达涉及有关禁令或类似通知、要求，税安科技将在法律和国家机关允许的情况下尽可能及时地通知您。</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.4pt'>9.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='letter-spacing:.6pt'>您理解并同意，在使用本服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，税安科技不对任何信息的真实性、</span><span
      style='letter-spacing:.7pt'>适用性、</span><span style='letter-spacing:.65pt'>合法性承担责任，也不对由于使用服务致</span><span
      style='letter-spacing:.6pt'>使您可能收到的任何骚扰性、</span><span style='letter-spacing:
.7pt'>威胁性、诽谤性、冒犯性、侵</span><span
      style='letter-spacing:.6pt'>权或非法信息或传播承担任何</span><span
      style='letter-spacing:.5pt'>责任。这些风险包括但</span><span
      style='letter-spacing:.45pt'>不限于：</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:.5pt'>9.3.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.5pt'>因使用本协议项下的服务，遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失。</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><a
      name="OLE_LINK5"><span lang=EN-US style='letter-spacing:.5pt'>9.3.2<span
      style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
      style='letter-spacing:.5pt'>税安科技</span></a><span style='letter-spacing:.5pt'>依照法律的规定对广告商履行相关义务，您应当自行判断广告信息的真实性并为自己的判断行为负责，除法律明确规定外，您因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，税安科技不承担责任。</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:.5pt'>9.3.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='letter-spacing:.5pt'>产品内可能嵌入第三方网站的链接，是否访问这些链接由您自行决定，税安科技并不保证这些链接上所提供的任何信息、数据、观点、图片、陈述或建议的准确性、完整性、充分性和可靠性。税安科技产品内嵌入的第三方网站链接仅在于为用户提供方便，并不表示税安科技对这些信息的认可和推荐，也非用于宣传或广告目的。</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.4pt'>9.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.6pt'>一方违反本协议的约定，给另一方造成损失的，应当赔偿另一方的损失，赔偿金额以</span><span
      style='letter-spacing:.5pt'>违约方履行本协议已获得的直接经济利益</span><span style='letter-spacing:
.45pt'>为限。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.4pt'>9.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.45pt'>在任何情况下，税安科技均不对任何间接性、后果性、惩戒性、偶然性、特殊性的损害，</span><span
      style='letter-spacing:.7pt'>包括不对您使用税安科技服务而遭受的利润损</span><span style='letter-spacing:
.6pt'>失承担责任（即使您已被告知该等损失的可能</span><span
      style='letter-spacing:.4pt'>性）</span><span
      style='letter-spacing:.5pt'>。</span></p>

    <p class=MsoBodyText style='line-height:
150%'><b><span lang=EN-US style='letter-spacing:-.6pt'>10<span
      style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
      lang=EN-US style='letter-spacing:.35pt'>不可抗力</span></b></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.4pt'>10.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.6pt'>因不可抗力或者其他意外事件，使得本协议的履行不可能、不必要或者无意义的，遭</span><span
      style='letter-spacing:.45pt'>受不可抗力、</span><span
      style='letter-spacing:.55pt'>意外事</span><span
      style='letter-spacing:.5pt'>件的一方不承担责任。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.4pt'>10.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.6pt'>不可抗力、意外事件是指不能预见、不能克服并不能避免且对一方或双方当事人造成</span><span
      style='letter-spacing:.4pt'>重大影响的客观事件，包括但不限于自然灾害（如洪水、</span><span
      style='letter-spacing:.55pt'>地震、瘟疫流行等）以</span><span
      style='letter-spacing:.5pt'>及社会事</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      style='letter-spacing:.6pt'>件（如战争、</span><span style='letter-spacing:.65pt'>动乱、政府行为、电信主干线路中断、黑客、网路堵塞、电</span><span
      style='letter-spacing:.6pt'>信部门技术调整</span><span
      style='letter-spacing:.45pt'>和政府管制等）</span><span
      style='letter-spacing:.55pt'>等。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.4pt'>10.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.6pt'>遭遇不可抗力后，受影响方应采取适当措施减少或消除不可抗力产生的影响，并应在</span><span
      style='letter-spacing:.65pt'>可能的范围内尽快重新履行受不可抗力影响的义务；同时，</span><span
      style='letter-spacing:.6pt'>受影响方应立即通知相对方，</span><span style='letter-spacing:
.45pt'>并应于不可抗力发生后</span><span style='letter-spacing:.65pt'> </span><span
      lang=EN-US style='letter-spacing:.45pt'>15</span><span lang=EN-US
                                                             style='letter-spacing:.7pt'> </span><span
      style='letter-spacing:.45pt'>日内，向相对方提供必要、合理的证明文件。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.4pt'>10.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.6pt'>若不可抗力导致受影响方无法履行本协议下全部或部分义务持续达到</span><span
      style='letter-spacing:.3pt'> </span><span lang=EN-US style='letter-spacing:
.8pt'>60</span><span lang=EN-US style='letter-spacing:.35pt'> </span><span
      style='letter-spacing:.65pt'>日以上，则</span><span style='letter-spacing:.6pt'>双方应当进行协商，确定继续履行本协议的条件或终止本协议。若各方经协商后</span><span
      style='letter-spacing:.35pt'> </span><span lang=EN-US style='letter-spacing:
.9pt'>30</span><span lang=EN-US style='letter-spacing:.35pt'> </span><span
      style='letter-spacing:.6pt'>日内</span><span style='letter-spacing:.5pt'>无法就继续履行本协议的条件达成一致意见，则任何一方可立即向对方发出终</span><span
      style='letter-spacing:.45pt'>止通知。</span></p>

    <p class=MsoBodyText style='line-height:
150%'><b><span lang=EN-US style='letter-spacing:-.6pt'>11<span
      style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
      lang=EN-US style='letter-spacing:.4pt'>法律适用及争议解决</span></b></p>

    <p class=MsoBodyText style='line-height:
150%'><span lang=EN-US style='letter-spacing:-.4pt'>11.1<span style='font:7.0pt "Times New Roman"'>
</span></span><span lang=EN-US style='letter-spacing:.45pt'>  </span><span
      style='letter-spacing:.45pt'>本协议条款受中华人民共和国法律管辖。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.4pt'>11.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.6pt'>在执行本协议过程中如发生纠纷，双方应及时协商解决。协商不成时，任何一方有权</span><span
      style='letter-spacing:.5pt'>向浙江省杭州市滨江区人民法院提</span><span style='letter-spacing:
.45pt'>起诉讼。</span></p>

    <p class=MsoBodyText style='line-height:
150%'><b><span lang=EN-US style='letter-spacing:-.6pt'>12<span
      style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
      lang=EN-US style='letter-spacing:.25pt'>附则</span></b></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.4pt'>12.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><b><span style='letter-spacing:.55pt'>税安科技在交易平台相关页面上的服务说明、价格说明和您确认同意的订购页面是本协议不可分割的一部分。如果交易平台相关页面上的服务说明、</span></b><b><span
      style='letter-spacing:.7pt'>价格说明和您确认同意</span></b><b><span style='letter-spacing:
.65pt'>的订</span></b><b><span style='letter-spacing:.5pt'>购页面与本协</span></b><b><span
      style='letter-spacing:.45pt'>议有不一致之处，以本协议为准。</span></b></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.4pt'>12.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.6pt'>税安科技有权在不影响您使用本协议所订购税安科技产品的情况下，提前</span><span
      style='letter-spacing:.4pt'> </span><span lang=EN-US style='letter-spacing:
.85pt'>30</span><span lang=EN-US style='letter-spacing:.4pt'> </span><span
      style='letter-spacing:.6pt'>日在交易</span><span style='letter-spacing:.65pt'>平台上公布或给您发网站内通知或书面通知的方式将本协议</span><span
      style='letter-spacing:.6pt'>的权利义务全部或者部分转移</span><span style='letter-spacing:
.5pt'>给税安科技的关</span><span style='letter-spacing:.45pt'>联公司。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.4pt'>12.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='letter-spacing:.6pt'>如果任何条款在性质上或其他方面理应地在本协议终止时继续存在，那么应视为继续存在的条款，这些条款包括但不局限于保证条款、</span><span
      style='letter-spacing:.7pt'>知识产权条款、保密</span><span style='letter-spacing:.65pt'>条款、法律适用及</span><span
      style='letter-spacing:.5pt'>争议解决</span><span style='letter-spacing:.45pt'>条款。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:-.4pt'>12.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span
      style='letter-spacing:.6pt'>本协议中条款前所列索引关键词仅为帮助您理解该条款表达的主旨之用，不影响或限</span><span
      style='letter-spacing:.5pt'>制本协议条款的含义或解释。为维护您自身权益，建议您仔细阅读各条款具体</span><span
      style='letter-spacing:.45pt'>表述。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US>12.5<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      style='letter-spacing:.6pt'>本协议会在交易平台订购页面在您支付相应款项前通过数据电文方式生成，您可以在</span><span
      style='letter-spacing:.4pt'>付款前详细阅读、</span><span
      style='letter-spacing:.5pt'>下载、保存、打</span><span
      style='letter-spacing:.45pt'>印本协议的全部内容；如果您不接受本协议，请停止付</span><span
      style='letter-spacing:.5pt'>款，系统所生成的订单及本协议将自</span><span style='letter-spacing:
.45pt'>动失效。</span></p>

    <p class=MsoBodyText style='line-height:150%'><a
      name="OLE_LINK1"><span lang=EN-US style='letter-spacing:.6pt'>12.6<span
      style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      style='letter-spacing:.6pt'>双方均为独立缔约方。本协议不为双方创立合伙、合资、代理或雇佣关系</span></a><span
      style='letter-spacing:.6pt'>。</span></p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:.6pt'>12.7<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span
      style='letter-spacing:.6pt'>本协议自您提交订单并税安科技收到您支付的相应服务费之日起生效。</span>
    </p>

    <p class=MsoBodyText style='line-height:150%'><span
      lang=EN-US style='letter-spacing:.6pt'>12.8<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='letter-spacing:.6pt'>联系我们：如您对本协议有任何疑问，请联系我们：<span
      lang=EN-US>0571-85055930 </span>。</span></p>

    <p class=MsoNormal style='line-height:150%'><span lang=EN-US>&nbsp;</span></p>

    <p class=MsoBodyText style='line-height:
150%'><span
      style='letter-spacing:.65pt'>您再次确认并声明：您已经仔细阅读并完全理解本协议条款并愿意接</span><span
      style='letter-spacing:.6pt'>受上述所有条款</span><span style='letter-spacing:.5pt'>的约</span><span
      style='letter-spacing:.4pt'>束。</span></p>

  </div>
</template>

<style scoped lang="stylus">

</style>
