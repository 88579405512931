<script>
import AgreementText from "@/pages/components/AgreementText.vue";

export default {
  name: "Agreement",
  components: {AgreementText},
  methods: {
    agree() {
      this.$emit('agree')
    }
  }
}
</script>

<template>
  <div class="agreement-wrapper">
    <div style="font-size: .34rem;height: .78rem;font-weight: 600">智能财税服务平台服务协议</div>
    <div style="height: 70vh;overflow: auto;margin-right: -0.3rem;padding-right: .3rem">
      <AgreementText />
    </div>
    <div style="margin: .32rem 0 .2rem">
      <button class="btn btn-primary" @click="agree">已阅读并同意签署</button>
    </div>
  </div>
</template>

<style scoped lang="stylus">
.agreement-wrapper {
  color: #1D2129;
  font-weight: 400;
  padding .3rem;
  font-size: .28rem;
  font-style: normal;
  text-decoration: none;
  text-align: left;
}
</style>
