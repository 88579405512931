<template>
  <div>
    <div class="whiteStyle"></div>
    <div>
      <div class="h5-wrapper">
        <div class="content-section">
          <div class="title">收入类型分析</div>
          <RingBarTabs :data="incomeTypeData" v-if="incomeTypeData"></RingBarTabs>
        </div>
        <div class="content-section">
          <div class="title">成本费用分析</div>
          <RingBarTabs :data="costAnalysis" v-if="costAnalysis"></RingBarTabs>
        </div>
        <div class="content-section">
          <div class="title">核心财务数据</div>
          <CoreFinanceData :data="coreFinancialData" v-if="coreFinancialData"> </CoreFinanceData>
        </div>
        <div class="content-section" style="margin-bottom: 20px">
          <div class="title">偿债能力分析</div>

          <DebtPayingAbilityTabs
            :data="solvencyAnalysis"
            v-if="solvencyAnalysis"
          ></DebtPayingAbilityTabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RingBarTabs from "./components/RingBarTabs.vue";
import "../bossWatchReport.styl";
import DebtPayingAbilityTabs from "./components/DebtPayingAbilityTabs.vue";
import CoreFinanceData from "./components/CoreFinanceData.vue";
import {
  getCoreFinancialData,
  getCostAnalysis,
  getRevenueTypeAnalysis,
  getSolvencyAnalysis,
} from "../../../../api/bossWatchReport";
import BossTable from "../component/BossTable.vue";

export default {
  metaInfo: {
    title: "财务分析",
  },
  name: "financeAnalysis",
  components: { BossTable, CoreFinanceData, DebtPayingAbilityTabs, RingBarTabs },
  data() {
    return {
      incomeTypeData: null,
      costAnalysis: null,
      solvencyAnalysis: null,
      coreFinancialData: null,
    };
  },
  methods: {
    async loadIncomeType() {
      const uuid = this.$route.params.uuid;
      const res = await getRevenueTypeAnalysis({ uuid });
      this.incomeTypeData = res.data;
    },
    async loadCostAnalysis() {
      const uuid = this.$route.params.uuid;
      const res = await getCostAnalysis({ uuid });
      this.costAnalysis = res.data;
    },
    async loadSolvencyAnalysis() {
      const uuid = this.$route.params.uuid;
      const res = await getSolvencyAnalysis({ uuid });
      this.solvencyAnalysis = res.data;
    },
    async loadCoreFinancialData() {
      this.loaded = false;
      const uuid = this.$route.params.uuid;
      const res = await getCoreFinancialData({ uuid });
      this.coreFinancialData = res.data;
      this.loaded = true;
    },
  },
  mounted() {
    this.loadIncomeType();
    this.loadCostAnalysis();
    this.loadSolvencyAnalysis();
    this.loadCoreFinancialData();
  },
  computed: {},
};
</script>

<style lang="stylus" scoped>
.content-section {
  padding: 0px
  background: #FFFFFF;
  padding: 0.3rem 0.3rem 0.1rem;
  .title {
    display flex
    align-items center
    font-size: 0.36rem;
    font-weight: 400;
    color: #1D2129;
    line-height: 24px;
    font-weight: bold
  }

  .title::before {
    content: '';
    display: inline-block;
    width: 0.08rem;
    height: 0.32rem;
    background: #2663F6;
    border-radius: 4px;
    margin-right: 0.2rem;
  }
}

@media screen and (min-width:1300px) and (max-width: 2000px){
  .whiteStyle{
    height 40px;
   }
  .h5-wrapper {
    width: 375px;
    margin:0px auto;
    height:800px;
    overflow: auto;
    padding-bottom 0px
    box-shadow: 3px 2px 11px 2px #dedede;
    .card-wrapper{
      display flex
      justify-content space-between
      .info-card{
        width: 15vw;
        height: 7vw;
        border-radius: 4px;
        padding 12px;
        box-sizing border-box
        box-shadow: 0px 1px 10px 2px rgba(0,0,0,0.1);
        .text0{
          margin-bottom 8px
          font-size: 14px;
          font-weight: 400;
          color: #4E5969;
          line-height: 20px;
          font-weight: bold;
        }
        .text1{
          font-size: 12px;
          font-weight: 400;
          color: #4E5969;
          line-height: 18px;
          font-weight: bold;
        }
      }
    }
  }
  >>>.btn-order{
    position: fixed
    top 827px
  }

>>>.content-section .danger{
  width: 13.2vw;
}
}
@media screen and (min-width:600px) and (max-width: 1300px){
  .whiteStyle{
    height 10px;
   }
  .h5-wrapper {
    width: 375px;
    margin:0px auto;
    height:600px;
    overflow: auto;
    padding-bottom 0px
    box-shadow: 3px 2px 11px 2px #dedede;
    .card-wrapper{
      display flex
      justify-content space-between
      .info-card{
        width: 15vw;
        height: 7vw;
        border-radius: 4px;
        padding 12px;
        box-sizing border-box
        box-shadow: 0px 1px 10px 2px rgba(0,0,0,0.1);
        .text0{
          margin-bottom 8px
          font-size: 14px;
          font-weight: 400;
          color: #4E5969;
          line-height: 20px;
          font-weight: bold;
        }
        .text1{
          font-size: 12px;
          font-weight: 400;
          color: #4E5969;
          line-height: 18px;
          font-weight: bold;
        }
      }
    }
  }
  >>>.btn-order{
    position: fixed
    top 596px
  }

>>>.content-section .danger{
  width: 13.2vw;
}
}
</style>
