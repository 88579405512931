<template>
  <div class="orderInformation">
    <!-- <div class="title">申报数据采集</div>
    <div class="pay-bottom-text">
      同一时间同一账号不允许多站点登陆，若强行登陆会导致系统数据采集中断无法生成检测报告!
    </div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" id="demo-ruleForm">
      <el-form-item prop="name">
        <div class="enterpriseName">企业名称<span>*</span></div>
        <el-input v-model="ruleForm.name" placeholder="请输入企业名称"></el-input>
      </el-form-item>
      <el-form-item prop="name">
        <div class="enterpriseName">纳税人识别号<span>*</span></div>
        <el-input v-model="ruleForm.name" placeholder="请输入纳税人识别号"></el-input>
      </el-form-item>
      <el-form-item prop="name">
        <div class="enterpriseName"><i class="el-icon-warning"></i>电子税局账号<span>*</span></div>
        <el-input v-model="ruleForm.name" placeholder="请输入电子税局账号"></el-input>
      </el-form-item>
      <el-form-item prop="name">
        <div class="enterpriseName">委托人手机号/身份证号码/操作员代码</div>
        <el-input
          v-model="ruleForm.name"
          placeholder="请输入委托人手机号/身份证号码/操作员代码"
        ></el-input>
      </el-form-item>
      <el-form-item prop="name">
        <div class="enterpriseName"><i class="el-icon-warning"></i>个人用户密码<span>*</span></div>
        <el-input v-model="ruleForm.name" placeholder="请输入个人用户密码"></el-input>
      </el-form-item>
      <el-form-item prop="region">
        <div class="enterpriseName">登陆身份<span>*</span></div>
        <el-select v-model="ruleForm.region" placeholder="请选择登陆身份">
          <el-option label="区域一" value="shanghai"></el-option>
          <el-option label="区域二" value="beijing"></el-option>
        </el-select>
      </el-form-item>
      <div style="height: 0.15rem"></div>
      <div class="btn-item" @click="submitForm('ruleForm')">提交</div>
    </el-form> -->
    <div class="topContent">
      <div class="selectButton">
        <div
          :class="state != 'data_authorize' && state != 'data_manual_upload' ? 'disabled' : state == 'data_authorize' ? 'selected' : ''"
          @click="stateChange('data_authorize')">授权自动采集数据 <i class="iconfont icon-icon_succeed"
                                                             v-if="autoFinish"></i>
        </div>
        <div
          :class="state != 'data_authorize' && state != 'data_manual_upload' ? 'disabled' : state == 'data_manual_upload' ? 'selected' : ''"
          v-if="withHands" @click="stateChange('data_manual_upload')">手动补充数据 <i v-if="handsFinish"
                                                                                class="iconfont icon-icon_succeed"></i>
        </div>
      </div>
      <!-- <img src="../assets/jiantou01.png" v-if="withHands" alt=""> -->
      <img src="../assets/jiantou02.png" class="little" alt="">
      <div class="selectButton">
        <div :class="state == 'analyze' ? 'selected' : 'disabled'">统计分析</div>
      </div>
      <img src="../assets/jiantou02.png" class="little" alt="">
      <div class="selectButton">
        <div :class="state == 'generate' ? 'selected' : 'disabled'">生成报告</div>
      </div>
    </div>
    <iframe class="fetchBaseUrl" ref="iframeRef" v-show="state == 'data_authorize'"
            v-if="!autoFinish"
            :src="fetchBaseUrl"></iframe>
    <!-- 自动采集中 -->
    <div v-else-if="state == 'data_authorize' && autoFinish" class="imgbox">
      <img src="../assets/autoing.png" alt="">
      <div class="imgTitle">数据自动采集中，预计 <span class="imgNumber">2</span> 小时内完成<br>过程中可关闭浏览器</div>
      <div class="imgTip">
        <p>• 在检测过程中，请勿使用该公司账号登录电子税务局，或者在其他平台进行数据授权，否则数据采集将被中断。</p>
        <p>• 当同一个办税员绑定多家企业时，系统不支持同时授权，否则可能导致数据采集中断，无法生成报告。</p>
      </div>
    </div>
    <!-- 手动补充阶段 -->
    <div v-if="state == 'data_manual_upload'" class="imgbox">
      <img src="../assets/noposter.png" alt="">
      <div class="imgTitle">当前页面交互较复杂，请到电脑端操作或跳过进入下一环节</div>
      <div class="imgTip">
        <p>• 手动补充数据指导入序时账、余额表等内容，从而提升检测报告的准确度。</p>
        <p>• 当前操作不适合手机端，故系统将自动跳过这一步。您可以在报告生成后，前往电脑端补充数据。 </p>
      </div>
      <!-- <div class="url" id="copyInner">www.console.ai-tax.cn</div>
      <div class="copy" @click="copy">
        复制链接
      </div>
      <div class="info">
        用手机验证码登陆电脑端进操作
      </div> -->
    </div>
    <!-- 统计分析阶段 -->
    <div v-if="state == 'analyze'" class="imgbox">
      <img src="../assets/noposter.png" alt="">
      <div class="imgTitle">报告生成中，请稍候…</div>
    </div>
    <!-- 报告生成 -->
    <div v-if="state == 'generate'" class="imgbox">
      <img src="../assets/reportSuccess.png" alt="">
      <div class="imgTitle">报告已生成，请前往查看报告</div>
      <div class="copy" @click="toReport" style="width:4rem;margin-top: 0.6rem;">
        查看报告
      </div>
    </div>
    <div class="tips" @click="openTips" v-if="state == 'data_authorize'&& !autoFinish">
      <i class="iconfont icon-icon_tishi"></i>
      <span style="margin-top:6px;">温馨提示</span>
    </div>
    <shouquan @read='read' ref="shouquanRef"></shouquan>
    <sharePopup ref="sharePopupRef"></sharePopup>
    <waitloading ref="waitloadingref"></waitloading>
    <van-dialog v-model="showDialog" title="温馨提示" :cancel-button-color="'#165DFF'"
                confirm-button-text="知道了"
                confirm-button-color="#fff">
      <div class="dialogContent">
        <div>1、在检测过程中，请勿使用该公司账号登录电子税务局，或者在其他平台进行数据授权，否则数据采集将被中断。</div>
        <div>2、当同一个办税员绑定多家企业时，系统不支持同时授权，否则可能导致数据采集中断，无法生成报告。</div>
      </div>
    </van-dialog>
    <van-dialog v-model="showDialog2" title="温馨提示" :cancel-button-color="'#165DFF'"
                confirm-button-text="知道了"
                confirm-button-color="#fff">
      <div class="dialogContent">
        <div>无法选择，报告生成后可在【数据分析工具】中查看或编辑</div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import { commitAuthorized, getAuthorizeStatus, getCheckUser } from "@/api/fri";
import { getTestingProcessGraphic } from "@/api/api"
import shouquan from "@/pages/components/shouquan.vue"
import sharePopup from "@/pages/components/sharePopup.vue"
import waitloading from "@/pages/components/waitloading.vue"

const baseEnv = process.env.VUE_APP_PDF_ROOT_FETCH;
const baseEnvReport = process.env.VUE_APP_GOLD_REPORT_VIEW;

import { copyText } from "@/utils/help.js"

export default {
  components: { shouquan, sharePopup, waitloading },
  metaInfo: {
    title: "申报数据采集",
  },
  data () {
    return {
      postMsg: 0,
      checkTimer: null,
      fetchBaseUrl: "",
      state: '',
      showDialog: false,
      showDialog2: false,
      autoFinish: false,
      handsFinish: false,
      withHands: false,
      checkTimer: null
    };
  },
  watch: {
    postMsg (val) {
      if (!val) return;
      this.commit()
    },
  },
  async mounted () {
    await this.fetchUrl();
    // await this.removeLister();
    await this.getState()
    await this.loadProgress()
    this.checkTimer = window.setInterval(() => {
      this.loadProgress()
      // 每五分钟执行 6000*10 * 5
    }, 50000);

    window.addEventListener("message", (e) => {
      if (e.data == "v2-submit-success") {
        this.postMsg = 101;
      } else if (e.data == 'showLicen') {
        this.$refs.shouquanRef.openVanPopupShareShow()
      } else if (e.data == 'shareCode') {
        this.$refs.sharePopupRef.openVanPopupShareShow()

      } else if (!e.data.data && !e.data.url) {
        if (e.data.taskId) {
          this.$refs['waitloadingref'].open(e.data)
        }
      }
    });
  },
  destroyed () {
    this.removeLister();
  },
  methods: {
    toReport () {
      let data = JSON.parse(this.$route.query.info)
      if (data.goodsId == 20) {
        //去老板看报告
        this.$router.push({
          path: "/userOnly/boss-watch-report/" + this.$route.params.uuid + "/" + this.$route.params.reportId,
          query: {
            // id: this.goodsList.policyId,
            channelUniqueId: localStorage.getItem("channelUniqueId"),
            code: this.$route.query.code,
          },
        });
        return;
      } else {
        let loginUrl = baseEnvReport + 'reportCenter/' + this.$route.params.reportId + '?token=' + localStorage.getItem('token') + '&desensitizationStatus=0'
        window.open(loginUrl)
        return;
      }
    },
    copy () {
      copyText("copyInner");

    },
    stateChange (e) {
      if (this.state != 'data_authorize' && this.state != 'data_manual_upload') {
        this.showDialog2 = true
      } else {
        this.state = e
      }
    },
    async getState () {
      const res = await getTestingProcessGraphic(this.$route.params.uuid)
      if (res.code == 200) {
        this.state = res.data.currentItemCode
        if (this.state == 'generate') {
          await this.removeLister();
        }
        this.autoFinish = res.data.graphicItemMap['data_authorize'].finish


        const keyArr = Object.keys(res.data.graphicItemMap)
        let handIndex = keyArr.findIndex(i => i == 'data_manual_upload')
        if (handIndex > -1) {
          this.withHands = true
          this.handsFinish = res.data.graphicItemMap['data_manual_upload'].finish
        }
      }
    },
    openTips () {
      this.showDialog = true
    },
    read () {
      this.$refs.iframeRef.contentWindow.postMessage('message', '*');

    },
    loadProgress () {
      const params = {
        programId: this.$route.params.uuid,
      };
      getAuthorizeStatus(params).then(async (res) => {
        if (res.data) {
          this.$message.success("填写成功");
          await this.commit();

        }
      });
    },
    async commit () {
      let data = JSON.parse(this.$route.query.info);
      let params = {
        // ...data,
        // organizationId: localStorage.getItem("organizationId"),
        // programId: this.$route.params.uuid,
        authorized: 1,
        programId: this.$route.params.uuid,
      };


      commitAuthorized(params).then((res) => {
        if (res.code != 200) {
          this.$message.error(res.message);
        } else {
          this.getState()
          // this.$router.push({
          //   name: "reportBeing",
          //   query: {
          //     channelUniqueId: localStorage.getItem("channelUniqueId"),
          //     code: this.$route.query.code
          //   }
          // });
        }
      });


      this.removeLister();
    },
    clearBrowserNavigatorHistory () {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", function() {
        history.pushState(null, null, document.URL);
      });
    },
    removeLister () {
      clearInterval(this.checkTimer);

      window.removeEventListener(
        "popstate",
        () => {
          history.pushState(null, null, document.URL);
        },
        false
      );
      window.removeEventListener("message", () => { });
    },
    fetchUrl () {
      if (!this.$route.query.info) return;
      let data = JSON.parse(this.$route.query.info);

      let info = {
        ...data,
        companyNo: data.taxNo,
        companyTaxNo: data.taxNo,
        authorization: localStorage.getItem('authorization'),

        programId: this.$route.params.uuid,
        preInspectId: this.$route.params.uuid,
        source: 1,
      };
      const zq = data.zq + "004";
      this.fetchBaseUrl =
        baseEnv +
        "oauthV2/?version=2"
      // "oemV2/" +
      // zq +
      // "/auth?version=2&info=" +
      // JSON.stringify(info) +
      // "&from=jinshuian&token=" +
      // localStorage.getItem("token");

      let message = {
        messageInfo: 'setMessage',
        ...info,
        authorization: localStorage.getItem('token'),
        startYear: info.startTestingDate,
        endYear: info.endTestingDate,
        appTypeName: 'jsa'
      }
      setTimeout(() => {
        this.$refs.iframeRef.contentWindow.postMessage(JSON.stringify(message), '*');
      }, 2000);
    },
  },
};
</script>
<style lang="stylus" scoped>
.imgbox {
  .url {
    margin: 0.6rem 0 0.48rem 0;
    color: #1D2129;
    font-size: 17px;
  }
  .info {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    width: 5.88rem;
    color: #606368;
    margin-top: 0.4rem;
    margin-bottom: 0.8rem;
    text-align: center;
  }
  .copy {
    width: 5.6rem;
    height: 0.88rem;
    background: #165DFF;
    border-radius: 4px 4px 4px 4px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    box-sizing: border-box;
    padding-top: 12px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.55rem;
  .imgTip {
    font-family: PingFangSC-Regular, PingFangSC-Regular;
    font-weight: normal;
    font-size: 12px;
    color: #606368;
  }
  img {
    width: 2.4rem;
    height: 2.4rem;
    margin-top: 2.56rem;
    margin-bottom: 0.8rem;
  }
  .imgTitle {
    text-align: center;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #1D2129;
    span {
      color: #165DFF;
    }
  }
}
.dialogContent {
  padding: 0.32rem 0.3rem;
  > div {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 17px;
    color: #606368;
  }
  > div + div {
    margin-top: 0.32rem;
  }
}
>>> .van-button__content {
  color: #165DFF;
}
.disabled {
  color: #A4A6A9
}
.selected {
  background: #E7EEFF !important;
  color: #165DFF;
  font-weight: 600;
}
.fetchBaseUrl {
  width: 100%;
  //height: 676px;
  height: calc(100vh - 100px);
  border: none;
  //background: #F4F6F8;
}
.orderInformation {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: #fff;
  //padding: 0.4rem 0.3rem;
  box-sizing: border-box;
  > .topContent {
    box-shadow: 0px .5px 0px .5px rgba(0, 0, 0, 0.06);
    i {
      color: #10CC99;
      font-size: 12px;
    }
    > div > div {
      width: 1.48rem;
      height: 0.64rem;
      text-align: center;
      font-size: 12px;
      line-height: 2.8;
      background: #F4F6F8;
      border-radius: 4px;
    }
    > div:first-child > div {
      width: 2.42rem;
    }
    > div:first-child > div + div {
      margin-top: 0.16rem;
    }
    > div:first-child {
      display: flex;
      flex-direction: column;
    }
    padding: 0.2rem 0.3rem;
    background: #fff;
    display: flex;
    align-items: center;
    > img {
      width: 0.56rem;
      height: 0.98rem;
      margin: 0 0.16rem;
    }
    img.little {
      width: 0.44rem;
      height: 0.19rem;
    }
  }
  > .tips {
    position: absolute;
    line-height: 1.25;
    padding-top: 0.2rem;
    right: 0;
    top: 7.4rem;
    width: 0.72rem;
    height: 2rem;
    background: #FFF7E8;
    box-shadow: 0px 0px 8px .1px rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    padding-left: 0.25rem;
    border-radius: 6px 0px 0px 6px;
    color: #FDB320;
    writing-mode: vertical-lr;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #FDB320;
    //text-align: center;
    //display: flex;
    flex-direction: column;
    align-items: center;
    > i {
      font-size: 0.28rem;
      color: #FDB320;
    }
  }
  .title {
    font-size: 0.32rem;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    margin: 0.3rem 0 0.2rem 0;
  }
  .enterpriseName {
    span {
      color: #fa4f4f;
      margin-left: 0.1rem;
      font-size: 0.28rem;
    }
  }
}
>>> .el-form-item {
  //display: flex;
  //flex-direction: column;
}
.btn-item {
  //width: 6.9rem;
  height: 0.88rem;
  line-height: 0.88rem;
  background: #3F74F6;
  border-radius: 0.1rem;
  opacity: 1;
  color: #fff;
  text-align: center;
  font-size: 0.34rem;
}
.pay-bottom-text {
  display: flex;
  align-items: baseline;
  //width: 6.5rem;
  background: rgba(253, 179, 32, 0.08);
  border-radius: 0.1rem;
  opacity: 1;
  margin-top: 0.3rem;
  color: #FDB320;
  font-size: 0.26rem;
  padding: 0.2rem 0.3rem;
  box-sizing: border-box;
  line-height: 0.39rem;
  border: 1px solid #FDB320;
  margin-bottom: 0.3rem;
}
.el-icon-warning {
  margin-right: 0.16rem;
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.28rem;
}
#demo-ruleForm {
  >>> .el-form-item__content {
    margin-left: 0rem !important;
  }
  >>> .el-form-item {
    margin-bottom: 0.25rem;
  }
  >>> .el-select {
    width: 6.9rem;
  }
}
</style>
