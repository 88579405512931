<script>
export default {
  name: "CountDown",
  props:{
    milliseconds:Number
  },
  data(){
    return {
      millisecondsLeft:1000*60*60,
      timer:null
    }
  },
  computed:{
    //把剩余时间转换成小时:分钟:秒
    timeLeft() {
      let hours = Math.floor(this.millisecondsLeft / 1000 / 60 / 60).toString().padStart(2,'0')
      let minutes = Math.floor(this.millisecondsLeft / 1000 / 60 % 60).toString().padStart(2,'0')
      let seconds = Math.floor(this.millisecondsLeft / 1000 % 60).toString().padStart(2,'0')
      return `${hours}:${minutes}:${seconds}`
    }
  },
  methods:{
    timeHandler() {
      if (this.millisecondsLeft > 0) {
        this.millisecondsLeft -= 1000
      }else{
        this.$emit( 'timeout')
        clearInterval(this.timer)
      }
    }
  },
  mounted() {
    this.millisecondsLeft = this.milliseconds
    this.timer = setInterval(this.timeHandler,1000)
  },
  destroyed() {
    clearInterval(this.timer)
  }
}
</script>

<template>
<span>{{timeLeft}}</span>
</template>

<style scoped lang="stylus">

</style>
