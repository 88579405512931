<template>
  <div v-if="goodsList && goodsList.length > 0" class="home-page" id="target">
    <login
      class="home-login"
      :goodsList="goodsList[swipeIndex]"
      :key="keyData"
      @addPhone="addPhone"
    ></login>
    <mobile
      class="home-mobile"
      @closeMobile="closeMobile"
      @bindPhoneWx="bindPhoneWx"
      v-if="isMobile"
    ></mobile>
    <img class="arrow-left" src="@/assets/arrow_left.png" @click="changeSwiper('left')" alt="" />
    <img class="arrow-right" src="@/assets/arrow_right.png" @click="changeSwiper('right')" alt="" />
    <div class="home-pages">{{ swipeIndex + 1 }}/{{ goodsList.length }}</div>
    <mt-swipe
      ref="swipe"
      :auto="0"
      :show-indicators="false"
      :style="{ height: `${swiperHieght}px` }"
      @change="IMGChange"
    >
      <mt-swipe-item v-for="item in goodsList" :key="item.id">
        <img ref="img" class="swiper-img" :src="item.posterUrl" alt="" />
      </mt-swipe-item>
    </mt-swipe>
  </div>
</template>
<script>
import login from "./components/login.vue";
import mobile from "./components/mobile.vue";

import { wechatAuth, getProductListByChannelUniqueId, autoLogin, getTitleApi } from "@/api/fri";

export default {
  metaInfo: {
    title: localStorage.getItem("titleName"),
  },
  props: {
    channelUniqueId: {
      type: String,
      default: localStorage.getItem("channelUniqueId")
    },
    posterUniqueId: {
      type: String,
      default: localStorage.getItem("posterUniqueId") != null && localStorage.getItem("posterUniqueId") != 'null' ? localStorage.getItem("posterUniqueId") : ''
    }
  },
  components: {
    login,
    mobile,
  },
  data () {
    return {
      isMobile: true,
      url: "",
      userData: "",
      goodsList: [],
      keyData: 1,
      posterUrl: "",
      //
      swiperHieght: 0,
      swipeIndex: 0,
      heightList: [],
      titleName: "",

    };
  },
  created () {
    this.init();
  },
  //配置页头
  beforeRouteEnter (to, from, next) {
    let channelUniqueId = localStorage.getItem("channelUniqueId");
    if (to.name != from.name) {
      getTitleApi(channelUniqueId).then((res) => {
        if (res.code == 200) {
          document.title = res.data;
          localStorage.setItem("titleName", res.data);
        } else {
          this.$message.error(res.message);
        }
      });
      next();
    }
    getTitleApi(channelUniqueId).then((res) => {
      if (res.code == 200) {
        document.title = res.data;
        localStorage.setItem("titleName", res.data);
      } else {
        this.$message.error(res.message);
      }
    });
    next();
  },
  async mounted () {
    this.init();
    let channelUniqueId = localStorage.getItem("channelUniqueId");
    getTitleApi(channelUniqueId).then((res) => {
      if (res.code == 200) {
        // console.log(res.data, "mounted");
        // console.log(localStorage.getItem("titleName"), "mounted");
        document.title = res.data;
        localStorage.setItem("titleName", res.data);
      } else {
        this.$message.error(res.message);
      }
    });
    this.isMobile = localStorage.getItem("isMobile") === "true" ? true : false;
    // 因为授权成功之后重定向会回到首页，这个时候缓存会丢失，channelUniqueId不存在就不知道是哪个渠道商，但微信提供一个参数通过state参数将channelUniqueId携带到首页
    // 重新存入缓存
    // if (this.$route.query.state) {
    //   localStorage.setItem("channelUniqueId", this.$route.query.state);
    // }
    // // 新用户扫码会带channelUniqueId这个参数，先存在缓存
    // if (this.$route.query.channelUniqueId) {
    //   localStorage.setItem("channelUniqueId", this.$route.query.channelUniqueId);
    // }

    // 获取swiper所需要的商品
    setTimeout(async () => {
      await this.getProductLis();

    }, 2000)


    // 因为从授权页面携带过来的code只能用一次 所以缓存里面有token的情况下，就不访问wechatAuth这个接口
    // 而是访问自动登录刷新token的同时，取头像 昵称 手机号码
    // let token = localStorage.getItem("token");
    // if (token) {
    //   this.isMobile = false;
    //   let params = {
    //     channelUniqueId:
    //       this.$route.query.channelUniqueId || localStorage.getItem("channelUniqueId"),
    //   };
    //   autoLogin(params).then((res) => {
    //     if (res.code == 200) {
    //       if (res.data.headImgUrl) {
    //         localStorage.setItem("headImgUrl", res.data.headImgUrl);
    //       }
    //       if (res.data.nickname) {
    //         localStorage.setItem("nickname", res.data.nickname);
    //       }
    //       if (res.data.phoneNumber) {
    //         localStorage.setItem("phoneNumber", res.data.phoneNumber);
    //       }
    //       if (res.data.organizationId) {
    //         localStorage.setItem("organizationId", res.data.organizationId);
    //       }
    //       if (res.data.organizationId) {
    //         localStorage.setItem("organizationId", res.data.organizationId);
    //       }
    //       // 因为微信授权取不到手机号，所有这里设计了一个弹窗，用户手动填写
    //       // if (res.data.phoneNumber==null) {
    //       //   this.isMobile = true;
    //       // }
    //     }
    //   });
    //   return;
    // } else {
    //   // 用户首次进入
    //   this.url = window.location.href;
    //   console.log("用户首次进入");
    //   this.isMobile = true;
    //   // 如果存在code,就用code去取一下参数，存在code说明已经从授权页面重定向过来
    //   this.loginCode = this.$route.query.code;
    //   if (this.loginCode) {
    //     const loading = this.$loading({
    //       lock: true,
    //       text: "Loading",
    //       spinner: "el-icon-loading",
    //       background: "rgba(0, 0, 0, 0.7)",
    //     });
    //     // 请求接口 用code换参数
    //     let params = {
    //       code: this.$route.query.code,
    //       channelUniqueId: localStorage.getItem("channelUniqueId") || this.$route.query.state,
    //     };
    //     wechatAuth(params).then((res) => {
    //       if (res.data.code == 200) {
    //         this.userData = res.data.data;
    //         localStorage.setItem("headImgUrl", this.userData.headImgUrl);
    //         localStorage.setItem("nickname", this.userData.nickname);
    //         localStorage.setItem("unionId", this.userData.unionId);
    //         localStorage.setItem("organizationId", this.userData.organizationId);

    //         //
    //         // 通过这边的参数判断需不需要填写手机号
    //         if (this.userData.phoneNumber == null) {
    //           this.isMobile = true;
    //         } else {
    //           // 存下token
    //           this.isMobile = false;
    //           localStorage.setItem("token", res.headers["authorization"]);
    //           localStorage.setItem("phoneNumber", this.userData.phoneNumber);
    //         }
    //       }else{
    //         this.$router.push({
    //           path: "/authorize",
    //           query: {
    //             channelUniqueId: params.channelUniqueId,
    //           },
    //         });
    //       }
    //       loading.close();
    //     });
    //   } else {
    //     this.isMobile = false;
    //   }
    // }
  },

  methods: {
    init () {
      let channelUniqueId = localStorage.getItem("channelUniqueId");
      getTitleApi(channelUniqueId).then((res) => {
        if (res.code == 200) {
          localStorage.setItem("titleName", res.data);
          document.title = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    IMGChange (e) {
      this.swipeIndex = e;
      this.swiperHieght = this.heightList[this.swipeIndex];
      document.getElementById("target").scrollIntoView();
    },
    changeSwiper (val) {
      if (val == "right") {
        if (this.swipeIndex == this.goodsList.length - 1) return;
        this.$refs.swipe.next();
      } else {
        if (this.swipeIndex == 0) return;
        this.$refs.swipe.prev();
        this.swiperHieght = this.heightList[this.swipeIndex];
      }
    },
    bindPhoneWx () {
      this.isMobile = false;
    },
    closeMobile () {
      this.isMobile = false;
    },
    addPhone () {
      this.isMobile = true;
    },
    async getProductLis () {
      let params = {
        channelUniqueId: this.channelUniqueId ? this.channelUniqueId : localStorage.getItem("channelUniqueId"),
        posterUniqueId: this.posterUniqueId ? this.posterUniqueId : localStorage.getItem("posterUniqueId") != null && localStorage.getItem("posterUniqueId") != 'null' ? localStorage.getItem("posterUniqueId") : '',

      };
      const res = await getProductListByChannelUniqueId(params)
      if (res.code == 200) {
        this.goodsList = res.data;
        this.keyData++;
        // 通过先获取屏幕宽度按比例算出图片高度 来适应swiper插件所需的高度参数
        let cw = document.documentElement.clientWidth;
        this.heightList = this.goodsList.map((item) => {
          return (item.imageHeight * cw) / item.imageWidth;
        });

        this.swiperHieght = this.heightList[this.swipeIndex];
      }
      // 图片宽 屏幕宽
      // 图片高 屏幕高x
    },
    clearStro () {
      localStorage.clear();
      sessionStorage.clear();
    },
    clearBrowserNavigatorHistory () {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", function() {
        history.pushState(null, null, document.URL);
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.home-page {
  padding-bottom: 2.2rem;
  img {
    width: 100%;
  }
  .arrow-left {
    position: fixed;
    left: 0.3rem;
    top: 35%;
    width: 0.68rem;
    height: 0.68rem;
    z-index: 1001;
  }
  .arrow-right {
    position: fixed;
    right: 0.3rem;
    top: 35%;
    width: 0.68rem;
    height: 0.68rem;
    z-index: 1001;
  }
}
.home-login, .home-mobile {
  position: fixed;
  bottom: 0;
  z-index: 1000;
}
.home-mobile {
  z-index: 1002;
}
.home-pages {
  position: fixed;
  right: 0.3rem;
  bottom: 3.18rem;
  width: 0.82rem;
  height: 0.44rem;
  line-height: 0.44rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  color: #fff;
  text-align: center;
  font-size: 0.24rem;
  z-index: 1001;
}
</style>
