import moment from "moment/moment";

/**
 * 时间格式化
 * @param {string} date
 * @param {string} format
 * @returns {number}
 */
export function timeFormat(date,pattern="YYYY-MM-DD") {
  return moment(date).format(pattern)
}
