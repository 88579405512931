import Vue from "vue";
import "@/prototype";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import App from "./App.vue";
import router from "./router";

console.log("version 1220")

// 重写message消息
import { message } from "./resetMessage.js"

import './config/rem'

import VConsole from 'vconsole';

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
  //const vConsole = new VConsole();
}

import Vant from 'vant';
import 'vant/lib/index.css';

import VueMeta from 'vue-meta'
import '@/global.styl'
// import VConsole from "vconsole";
// const vconsole = new VConsole();
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
import FastClick from 'fastclick'

FastClick.attach(document.body);

Vue.use(ElementUI);
Vue.use(Vant);


Vue.prototype.$message = message;

Vue.config.productionTip = false;
//引入iconfont symbol图标
import "./assets/font/iconfont.css";
import "./assets/style/global.styl";
//
import { Swipe, SwipeItem } from 'mint-ui';

Vue.component(Swipe.name, Swipe);
Vue.component(SwipeItem.name, SwipeItem);
import "mint-ui/lib/style.css"

//
import store from "@/store/store.js";
//根据domain设置自定义版本
const debug_customization = ''//调试用

const domain = window.location.hostname.toLowerCase();

// if (debug_customization == 'hy.ai-tax.cn' || domain == 'hy.ai-tax.cn') {
//   store.commit('setCustomization', {customization: 'hy'})
// } else if (debug_customization == 'xr.ai-tax.cn' || domain == 'xr.ai-tax.cn') {
//   store.commit('setCustomization', {customization: 'xr'})
// } else if (debug_customization == 'nmhz.ai-tax.cn' || domain == 'nmhz.ai-tax.cn') {
//   store.commit('setCustomization', {customization: 'nmhz'})
// } else {
//   store.commit('setCustomization', {customization: ''})
// }
window.store = store;
// setTimeout(()=>{
//   store.commit('PdfResult/setToc', tableOfContentWithPageNo);
// },1000)

new Vue({
  router,
  store,
  // Vconsole:vconsole,
  render: h => h(App)
}).$mount("#app");


