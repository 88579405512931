<script>
import {formatToDecimal} from "../utils/format";
import {discount, getOrderInfo, getPayInfo, unifiedOrder} from "@/api/fri";
import {Dialog} from "vant";
import CountDown from "@/components/CountDown.vue";
import {cancelOrder} from "@/api/api";

export default {
  name: "Pay",
  components: {CountDown},
  metaInfo: {
    title: "支付",
  },
  props:{
    orderId:String
  },
  mounted() {
    this.getOrder()
  },
  destroyed() {
    //window.removeEventListener("popstate",this.popStateHandler );
  },
  data() {
    return {
      orderInfo: {},
      selectPayment: 0,
      payments: [{
        name: '微信支付',
        icon: require('@/assets/payments/icon_wxzf.svg')
      }
      // , {
      //   name: '支付宝支付',
      //   icon: require('@/assets/payments/icon_zfbzf.svg')
      // }, {
      //   name: '云闪付',
      //   icon: require('@/assets/payments/icon_ysfzf.svg')
      // }
      ]
    }
  },
   beforeRouteLeave (to, from, next) {
     const url = this.$router.resolve({name: 'detail',query:{orderNo:this.orderId,alwaysAllowed:true}});
     if (window.alwaysAllowRedirect) {
       next()
       window.alwaysAllowRedirect = false
       return
     }else if (window.confirm("确定放弃本次支付？")) {
       window.alwaysAllowRedirect = true
       this.$router.replace(url.href)
       next(false)
     }else {
       next(false)
     }
  },
  methods:{
    formatToDecimal,
     popStateHandler (e) {
      console.log("dasdsa")
      e.preventDefault()
      //history.pushState(null, null, document.URL);

      // if (window.confirm("确定放弃本次支付？")) {
      //   const url = this.$router.resolve({name: 'detail',query:{orderNo:this.orderId}});
      //   this.$router.push(url.href)
      // }else {
      //   history.pushState(null, null, document.URL);
      //   console.log("quxiao")
      // }
    },
    getOrder() {
      getOrderInfo(this.orderId).then((res) => {
        if (res.code == 200) {
          this.orderInfo = res.data;
        }
      });
    },
    handlePaymentSelect(index){
      this.selectPayment = index
    },
    handlePaymentClick(){
      // if (!this.companyName || !this.mobile) {
      //   this.$message({
      //     message: "请输入完整信息！",
      //     type: "warning",
      //   });
      //   return;
      // }
      this.loading = this.$loading({
        lock: true,
        text: "正在提交...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      // let params = {
      //   goodsId: this.goodInfo.policyId,
      //   phoneNumber: this.mobile,
      // };
      //支付订单前校验系统登录手机号
      // let checkStatus = await checkPhoneNumber(params);
      // if (checkStatus.code != 200) {
      //   this.loading.close();
      // }
      // if (checkStatus.data) {
      let channelUniqueId = localStorage.getItem("channelUniqueId");

      let params = {
        orderNo: this.orderId
      };

      // 发起预支付
      unifiedOrder(params).then((res) => {
        console.log(res);
        this.loading.close();
        if (res.code == 200) {
          this.payInfo = res.data;
          this.StartWeiXinPay(this.payInfo);
          if (res.code != 200 && res.code != 5001) {
            this.$message({
              message: res.data,
              type: "warning",
            });
          }
        }
      });
    },
    StartWeiXinPay(pay_params) {
      console.log(pay_params);
      if (typeof WeixinJSBridge === "undefined") {
        if (document.addEventListener) {
          alert(1);
          document.addEventListener("WeixinJSBridgeReady", this.onBridgeReady(pay_params), false);
        } else if (document.attachEvent) {
          alert(2);
          document.attachEvent("WeixinJSBridgeReady", this.onBridgeReady(pay_params));
          document.attachEvent("onWeixinJSBridgeReady", this.onBridgeReady(pay_params));
        }
      } else {
        this.onBridgeReady(pay_params);
      }
    },

    onBridgeReady(pay_params) {
      let id = this.orderInfo.policyId;

      var that = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: "wx4de932054829ef8c", //公众号ID，由商户传入
          timeStamp: pay_params.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: pay_params.nonceStr, //随机串
          package: "prepay_id=" + pay_params.prepayId,
          signType: "MD5", //微信签名方式：
          paySign: pay_params.sign, //微信签名
        },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // that.$router.push({
            //   path: "/detail",
            //   query: {
            //     orderNo: pay_params.orderNo,
            //   },
            // });
            //支付成功跳转首页
            window.alwaysAllowRedirect = true
            that.$router.replace({
              path: "/paymentSuccessful",
              query: {
                id: id,
              },
            });
            // location.href = this.base + "/paymentSuccessful" + "?id=" + this.goodInfo.policyId;
          } else if (res.errMsg == "get_brand_wcpay_request:ok") {
            // location.href = this.base + "/paymentSuccessful" + "?id=" + this.goodInfo.policyId;
            window.alwaysAllowRedirect = true
            that.$router.replace({
              path: "/paymentSuccessful",
              query: {
                id: id,
              },
            });
          } else {
            alert("支付失败");
            // window.alwaysAllowRedirect = true
            // that.$router.replace({
            //   path: "/paymentSuccessful",
            //   query: {
            //     id: id,
            //   },
            // });
          }
        }
      );
    },

    handleCountDownTimeout(){
      location.reload()
    }
  }
}
</script>

<template>
  <div style="color: #1D2129;">
    <div
      style="display: flex;flex-direction: column;align-items: center;justify-content: center;margin-top: 0.6rem;">

      <img src="@/assets/icon_dzf.svg" alt="" style="width: 1.20rem;height: 1.20rem;">
      <div
        style="color: #1D2129;font-weight: 600;font-size: .4rem;margin-top: 0.32rem;margin-bottom: 0.16rem">
        <template v-if="orderInfo.orderStatus==-1">
          已取消
        </template>
        <template v-else-if="orderInfo.orderStatus==1">
          待支付
        </template>
        <template v-else-if="orderInfo.orderStatus==2">
          已完成
        </template>
      </div>
      <div style="font-weight: 400;font-size: .28rem;" v-if="orderInfo.orderCountDown!=null"><span>还剩</span> <count-down :milliseconds="parseInt(orderInfo.orderCountDown)" @timeout="handleCountDownTimeout"></count-down>
        <span>订单自动取消</span></div>
    </div>
    <div style="padding: 0 .3rem;margin-top: .6rem">
      <van-radio-group v-model="selectPayment">
        <div v-for="(payment,index) in payments" :key="index"
             @click="handlePaymentSelect(index)"
             style="background: #fff;margin-bottom: .2rem;display: flex;padding: .2rem;align-items: center;justify-content: space-between">
          <div style="display: flex;align-items: center">
            <img :src="payment.icon" style="width: .72rem;height: .72rem;margin-right: .24rem">
            <div style="font-size: .32rem">{{ payment.name }}</div>
          </div>
          <div>
            <van-radio :name="index"></van-radio>
          </div>


        </div>
      </van-radio-group>
    </div>

    <div class="footer-toolbar">
      <button class="btn btn-primary" style="font-size: .32rem;" @click="handlePaymentClick" v-if="orderInfo.orderStatus==1">
        确认支付 ￥{{ formatToDecimal(orderInfo.totalAmount) }}
      </button>
    </div>
  </div>
</template>

<style scoped lang="stylus">

</style>
