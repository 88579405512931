<template>
  <div>
    <div class="good-info"
         style="background: #fff;width: 7.1rem;position: relative;margin: 0.2rem auto 0;border-radius: 0.2rem;padding: 0.2rem;box-sizing: border-box">
      <div style="display: flex">
        <div class="haibaoPic">
          <img class="backPic" :src="orderInfo.showImageUrl" alt="" />
          <img class="version-logo"
               :src="getCornerPic(orderInfo.attrs)"
               alt="">
        </div>
        <div style="margin-left: 0.3rem;flex-grow: 1;">
          <span class="pay-titles" style="margin-top: 0.1rem">{{ orderInfo.productName }}</span>
          <div class="pay-price"
               style="display: flex;width: 100%;justify-content: space-between;margin-top: 0.48rem">
            <FancyDecimal :value="orderInfo.price"></FancyDecimal>
            <span class="goodsCount"> x{{ orderInfo.goodsCount }}</span>
          </div>
        </div>
        <div style="position: absolute;right: 0.4rem;top:0.2rem;width: 1.14rem">
          <img v-if="orderInfo.orderStatus == -1" class="yizhifu" src="@/assets/img_jygb.svg"
               alt="" />
          <img v-if="orderInfo.orderStatus == 1" class="yizhifu" src="@/assets/img_dzf.svg"
               alt="" />
          <img v-if="orderInfo.orderStatus == 2" class="yizhifu" src="@/assets/img_jywc.svg"
               alt="" />
        </div>
      </div>
      <!--商品属性-->
      <div class="good-attr">
        <p class="mx-line" v-for="(item,index) in orderInfo.attrs" :key="index">
          <span>{{ item.groupName }}</span>
          <span>{{ item.attrVal }}</span>
        </p>
      </div>
      <!--商品价格-->
      <div class="pay-total">
        <div class="txt">应付金额</div>
        <div class="txtMoney" style="color: #165dff !important;display: flex;align-items: flex-end">
          <FancyDecimal :value="orderInfo.price"></FancyDecimal>
        </div>
      </div>
    </div>
    <goodInfo class="info" :goodInfo="orderInfo.productConfigJson" style="display: none"></goodInfo>
    <div class="submit-account">
      <div class="title" v-if="orderInfo.templateId != 6">基本信息</div>
      <div class="item items">
        <div>订单编号</div>
        <div>{{ orderInfo.orderNo }}</div>
      </div>
      <div class="item items">
        <div>订单创建时间</div>
        <div>{{ orderInfo.createTime }}</div>
      </div>
      <div class="divider">
      </div>
      <!-- <div class="item items" v-if="orderInfo.templateId != 6">
         <div>套餐</div>
         <div>按检测次数收费</div>
       </div>-->
      <div class="item items" v-if="orderInfo.templateId != 6">
        <div>服务商名称</div>
        <!-- <div>{{ orderInfo.endCheckTime }}</div> -->
        <div>{{ orderInfo.channelName }}</div>
      </div>
      <!-- <template v-if="orderInfo.templateId != 6">
         <div class="item items" v-if="orderInfo.orderStatus == 2">
           <div>有效期至</div>
           <div v-if="orderInfo.endCheckTime">
             {{ timeFormat(orderInfo.endCheckTime,"YYYY-MM-DD") }}
           </div>
         </div>
         <div class="item items" v-else>
           <div>有效期</div>
           <div>
             <template v-if="orderInfo.effectiveDays">{{ orderInfo.effectiveDays }} 天</template>
           </div>
         </div>
       </template>-->
      <div class="item items" v-if="orderInfo.templateId != 6">
        <div>服务热线</div>
        <div>{{ orderInfo.orderPhone }}</div>
      </div>
      <div
        class="item items"
        v-if="orderInfo.templateId != 6"
      >
        <div>购买须知</div>
        <div>本产品不支持退款</div>
      </div>
      <!-- <div
        class="item items item-bottom"
        v-if="orderInfo.orderStatus != -1 && orderInfo.templateId != 6"
      >
        <div>初始密码</div>
        <div>123456</div>
      </div> -->
      <div class="divider">
      </div>
      <div v-if="orderInfo.orderStatus == 2">
        <div class="item items">
          <div>交易单号</div>
          <div>{{ orderInfo.bizPayNo }}</div>
        </div>
        <div class="item items">
          <div>支付类型</div>
          <div>{{ orderInfo.payType == 1 ? "微信支付" : "其他支付" }}</div>
        </div>
        <div class="item items">
          <div>交易完成时间</div>
          <div>{{ orderInfo.payTime }}</div>
        </div>
        <div class="divider">
        </div>
      </div>
      <div class="item items">
        <div style="font-weight: bold">实付金额</div>
        <div style="color: #165DFF;font-size: 0.28rem;font-weight: bold">¥
          {{ formatToDecimal(orderInfo.totalAmount) }}
        </div>
      </div>
    </div>
    <div class="footer-toolbar" v-if="orderInfo.orderStatus == 1"
         style="display:flex;justify-content: space-between">
      <button class="btn btn-link" style="width: auto;padding: 0;"
              @click="handleBtnCancelOrder">
        取消订单
      </button>
      <router-link :to="{name:'pay',params:{
          orderId:orderInfo.orderNo
        }}"
                   class="btn btn-primary "
                   style="width: 5.40rem;flex-shrink: 0;line-height: 1.5"
      >
        <div style="color: #fff;font-size: .32rem;"><span
          style="margin-right: 0.2rem">立即支付</span><span>¥ {{
            formatToDecimal(orderInfo.totalAmount)
          }}</span></div>
        <div style="font-size: .24rem;color: #BACFFF;" v-if="orderInfo.orderCountDown!=null">还剩
          <count-down :milliseconds="parseInt(orderInfo.orderCountDown)"
                      @timeout="handleCountDownTimeout"></count-down>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import payItem from "./components/pay-item.vue";
import goodInfo from "./components/good-info.vue";

import { getOrderInfo } from "@/api/fri";
import loginVue from "./components/login.vue";
import { formatToDecimal } from "../utils/format";
import { cancelOrder } from "@/api/api";
import { Dialog } from "vant";
import CountDown from "@/components/CountDown.vue";
import FancyDecimal from "@/components/FancyDecimal.vue";
import { timeFormat } from "@/utils/time";
import { getCornerPic } from '@/utils/help'

export default {
  metaInfo: {
    title: "订单详情",
  },
  components: {
    FancyDecimal,
    CountDown,
    payItem,
    goodInfo,
  },
  computed () {
  },
  data () {
    return {
      order: 2,
      orderInfo: {}
    };
  },
  mounted () {
    this.getOrder();
  },
  //df69ded9-0d78-4431-bda8-cd7cb975783d
  methods: {
    timeFormat,
    getCornerPic,
    formatToDecimal,
    handleCountDownTimeout () {
      location.reload()
    },
    async handleBtnCancelOrder () {

      Dialog.confirm({
        title: '确定取消该订单？',
        confirmButtonText: '确定取消',
        cancelButtonText: '暂不取消'
        //放弃
      }).then(async () => {
        const res = await cancelOrder({
          orderNo: this.$route.query.orderNo
        })
        if (res.code == 200) {
          location.reload()
        }
      }).catch(() => {

      });


    },
    getOrder () {
      getOrderInfo(this.$route.query.orderNo).then((res) => {
        if (res.code == 200) {
          this.orderInfo = res.data
        }
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.good {
  margin: 0.2rem 0 0.2rem 0.2rem;
}
.info {
  margin-left: 0.2rem;
}
.submit-account {
  width: 7.1rem;
  background: #FFFFFF;
  border-radius: 0.2rem;
  opacity: 1;
  font-size: 0.28rem;
  margin: 0.2rem 0 0 0.2rem;
  padding: 0 0.2rem;
  box-sizing: border-box;
  padding-bottom: 0.3rem;
  .title {
    font-size: 0.32rem;
    line-height: 0.46rem;
    padding-top: 0.3rem;
    padding-bottom: 0.4rem;
    font-weight: bold;
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1rem;
    border-bottom: 1px solid rgba(14, 20, 41, 0.1);
    box-sizing: border-box;
    >>> .el-input {
      width: 3.2rem;
    }
    >>> .el-input__inner {
      border: none;
      backround: #F8F9FB;
    }
  }
  .items {
    border-bottom: none;
    line-height: 0.4rem;
    margin-bottom: 0.3rem;
  }
  .items:last-child {
    border-bottom: none;
    line-height: 0.4rem;
    margin-bottom: 0rem;
  }
  .item-botom {
    border-bottom: none;
    padding-bottom: 0.38rem;
  }
  .item-code {
    padding-top: 0.3rem;
  }
}
.btn-c {
  width: 100%;
  background: #FFFFFF;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  padding: 0.2rem 0.3rem;
  box-sizing: border-box;
  margin-top: 0.4rem;
}
.submit-account .item-bottom {
  border-bottom: 1px solid rgba(14, 20, 41, .1) !important;
  margin-bottom: 0 !important;
  padding-bottom: 0.3rem;
}
.divider {
  border-bottom: 1px solid rgba(14, 20, 41, .1);
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}
.good-info {
  .good-attr {
    padding-top: 4px;
    .mx-line {
      font-weight: 400;
      padding-left: 0;
      width: 100%;
      line-height: 20px;
      font-size: 14px;
      color: #1D2129;
      display: flex;
      align-items center;
      justify-content space-between
    }
  }
  .pay-titles {
    display: inline-block;
    font-weight: bold;
    font-size 0.28rem
    overflow: hidden;
    text-overflow: ellipsis;
    width: 3rem;
    white-space: nowrap;
  }
  .goodsCount {
    font-weight: 400;
    color: #A4A6A9;
    font-size: 0.24rem;
    margin-top: 0.05rem;
  }
  .yizhifu {
    width 100%
  }
  .pay-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.28rem;
    padding-top: 0.3rem;
    padding-bottom: 0.1rem;
    border-top: 1px solid #E6E8EA;
    margin-top: 0.2rem;
    .txt {
      line-height: 0.4rem;
    }
    .txtMoney {
      color: #165DFF;
      vertical-align: baseline;
      display: flex;
    }
    .floatPrice {
      margin-top: 0.12rem;
    }
  }
}
</style>
